/* eslint-disable */

import { useNavigate, useParams, useLocation } from "react-router-dom";

interface BaseRow {
  uid: string; // Ensure that 'uid' exists on all rows
}

interface TableProps<T extends BaseRow> {
  data: T[];
  columns: { key: keyof T | string; label: string }[];
  rowsPerPage: number;
  onView: (id: string | undefined) => void;
}

// Update the Table component to handle nested properties
const Table = <T extends BaseRow>({
  data,
  columns,
  rowsPerPage,
  onView,
}: TableProps<T>) => {
  const navigate = useNavigate();
  const { page } = useParams<{ page: string }>();
  const location = useLocation();
  const currentPage = parseInt(page || "1", 10) - 1;

  const startIdx = currentPage * rowsPerPage;
  const endIdx = startIdx + rowsPerPage;
  const currentData = data.slice(startIdx, endIdx);
  const totalPages = Math.ceil(data.length / rowsPerPage);

  const goToPage = (pageNumber: number) => {
    const newPath = `${location.pathname.split("/").slice(0, -1).join("/")}/${pageNumber + 1}`;
    navigate(newPath);
  };

  const getNestedProperty = (obj: any, key: string) => {
    return key.split(".").reduce((o, i) => o[i], obj);
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border-collapse">
        <thead>
          <tr className="bg-gray-200">
            {columns.map((column) => (
              <th
                key={column.key.toString()}
                className="px-4 py-2 border border-gray-300 text-left text-gray-700"
                style={{ backgroundColor: "#EEEEEE" }}
              >
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentData.map((row, rowIndex) => (
            <tr
              onClick={() => onView(row.uid)}
              key={rowIndex}
              className="bg-white cursor-pointer"
            >
              {columns.map((column) => (
                <td
                  key={column.key.toString()}
                  className="px-4 py-2 border border-gray-300 text-gray-700"
                >
                  {getNestedProperty(row, column.key as string)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex justify-end items-center mt-4">
        <button
          onClick={() => goToPage(Math.max(currentPage - 1, 0))}
          disabled={currentPage === 0}
          className="px-3 py-1 mr-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50"
        >
          Previous
        </button>
        <span className="text-gray-700">
          Page {currentPage + 1} of {totalPages}
        </span>
        <button
          onClick={() => goToPage(Math.min(currentPage + 1, totalPages - 1))}
          disabled={currentPage >= totalPages - 1}
          className="px-3 py-1 ml-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Table;
