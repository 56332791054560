/* eslint-disable */

import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";

import axiosInstance from "../../common/AxiosInstance";
import { CreatePatientSymptoms } from "../../../config/types";

const updatePatientSymptoms = async (data: CreatePatientSymptoms) => {
  const response = await axiosInstance.patch(
    `patient/symptom/patient/${data.patient_uid}/`,
    data,
  );

  return response;
};

const useUpdatePatientSymptoms = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Response,
  CreatePatientSymptoms
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updatePatientSymptoms,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: ["patientsymptoms"] })
        .catch((error: Error) => {
          throw new Error(error.message);
        });
    },
  });
};

export default useUpdatePatientSymptoms;
