/* eslint-disable */

import { useState, useEffect } from "react";
import axios from "axios";

const useReverseGeocoding = (latitude: number, longitude: number) => {
  const [placeName, setPlaceName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  useEffect(() => {
    const fetchPlaceName = async () => {
      if (latitude && longitude) {
        setLoading(true);
        const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""; // Replace with your Google Maps API key
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

        try {
          const response = await axios.get(url);
          if (response.data.status === "OK") {
            const results = response.data.results;
            if (results.length > 0) {
              setPlaceName(results[0].formatted_address);
            } else {
              setPlaceName("No results found");
            }
          } else {
            setError(`Geocoding API error: ${response.data.status}`);
          }
        } catch (error: any) {
          setError("Error fetching geocoding data: " + error.message);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPlaceName();
  }, [latitude, longitude]);

  return { placeName, loading, error };
};

export default useReverseGeocoding;
