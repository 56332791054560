/* eslint-disable */

import { Option } from "react-multi-select-component";
export const arrayToCommaSeparatedString = (array: string[]): string => {
  return array.join(",");
};
export const commaSeparatedStringToArray = (str: string): string[] => {
  return str
    .split(",")
    .map((item) => item.trim())
    .filter((item) => item !== "");
};

export const flattenPatientData = (patient: any | undefined) => ({
  first_name: patient.user?.first_name,
  last_name: patient.user?.last_name,
  email: patient.user?.email,
  city: patient.location?.city,
  region: patient.location?.region,
  country: patient.location?.country?.name,
  phone_number: patient.phone_number,
  date_of_birth: patient.date_of_birth,
  uid: patient.uid,
});
export const languageOptions: Option[] = [
  { value: "ENGLISH", label: "English" },
  { value: "FRENCH", label: "French" },
  { value: "SPANISH", label: "Spanish" },
  { value: "GERMAN", label: "German" },
  { value: "CHINESE", label: "Chinese" },
  { value: "JAPANESE", label: "Japanese" },
  { value: "KOREAN", label: "Korean" },
  { value: "RUSSIAN", label: "Russian" },
  { value: "ITALIAN", label: "Italian" },
  { value: "PORTUGUESE", label: "Portuguese" },
  { value: "ARABIC", label: "Arabic" },
  { value: "HINDI", label: "Hindi" },
  { value: "BENGALI", label: "Bengali" },
  { value: "URDU", label: "Urdu" },
  { value: "TAMIL", label: "Tamil" },
  { value: "TELUGU", label: "Telugu" },
  { value: "VIETNAMESE", label: "Vietnamese" },
  { value: "THAI", label: "Thai" },
  { value: "DUTCH", label: "Dutch" },
  { value: "SWEDISH", label: "Swedish" },
  { value: "DANISH", label: "Danish" },
  { value: "NORWEGIAN", label: "Norwegian" },
  { value: "FINNISH", label: "Finnish" },
  { value: "POLISH", label: "Polish" },
  { value: "CZECH", label: "Czech" },
  { value: "HUNGARIAN", label: "Hungarian" },
  { value: "ROMANIAN", label: "Romanian" },
  { value: "TURKISH", label: "Turkish" },
  { value: "HEBREW", label: "Hebrew" },
  { value: "INDONESIAN", label: "Indonesian" },
  { value: "MALAY", label: "Malay" },
  { value: "SWAHILI", label: "Swahili" },
  { value: "LATIN", label: "Latin" },
  { value: "BULGARIAN", label: "Bulgarian" },
  { value: "SERBIAN", label: "Serbian" },
  { value: "CROATIAN", label: "Croatian" },
  { value: "SLOVAK", label: "Slovak" },
  { value: "ESTONIAN", label: "Estonian" },
  { value: "LITHUANIAN", label: "Lithuanian" },
  { value: "LATVIAN", label: "Latvian" },
];
export const painRates = [
  {
    label: "10",
    value: "10",
  },
  {
    label: "9",
    value: "9",
  },
  {
    label: "8",
    value: "8",
  },
  {
    label: "7",
    value: "7",
  },
  {
    label: "6",
    value: "6",
  },
  {
    label: "5",
    value: "5",
  },
  {
    label: "4",
    value: "4",
  },
  {
    label: "3",
    value: "3",
  },
  {
    label: "2",
    value: "2",
  },
  {
    label: "1",
    value: "1",
  },
];
export const nationalityOptions = [
  { value: "AMERICAN", label: "American" },
  { value: "CANADIAN", label: "Canadian" },
  { value: "BRITISH", label: "British" },
  { value: "FRENCH", label: "French" },
  { value: "GERMAN", label: "German" },
  { value: "ITALIAN", label: "Italian" },
  { value: "SPANISH", label: "Spanish" },
  { value: "CHINESE", label: "Chinese" },
  { value: "JAPANESE", label: "Japanese" },
  { value: "KOREAN", label: "Korean" },
  { value: "RUSSIAN", label: "Russian" },
  { value: "INDIAN", label: "Indian" },
  { value: "BRAZILIAN", label: "Brazilian" },
  { value: "AUSTRALIAN", label: "Australian" },
  { value: "SOUTH AFRICAN", label: "South African" },
  { value: "MEXICAN", label: "Mexican" },
  { value: "ARGENTINIAN", label: "Argentinian" },
  { value: "CHILEAN", label: "Chilean" },
  { value: "COLOMBIAN", label: "Colombian" },
  { value: "PERUVIAN", label: "Peruvian" },
  { value: "Venezuelan", label: "Venezuelan" },
  { value: "TURKISH", label: "Turkish" },
  { value: "SAUDI", label: "Saudi" },
  { value: "IRANIAN", label: "Iranian" },
  { value: "ISRAELI", label: "Israeli" },
  { value: "EGYPTIAN", label: "Egyptian" },
  { value: "NIGERIAN", label: "Nigerian" },
  { value: "KENYAN", label: "Kenyan" },
  { value: "SOMALI", label: "Somali" },
  { value: "SWEDISH", label: "Swedish" },
  { value: "DANISH", label: "Danish" },
  { value: "NORWEGIAN", label: "Norwegian" },
  { value: "FINNISH", label: "Finnish" },
  { value: "POLISH", label: "Polish" },
  { value: "CZECH", label: "Czech" },
  { value: "HUNGARIAN", label: "Hungarian" },
  { value: "ROMANIAN", label: "Romanian" },
  { value: "PORTUGUESE", label: "Portuguese" },
  { value: "BULGARIAN", label: "Bulgarian" },
  { value: "SERBIAN", label: "Serbian" },
  { value: "CROATIAN", label: "Croatian" },
  { value: "SLOVAK", label: "Slovak" },
  { value: "ESTONIAN", label: "Estonian" },
  { value: "LATVIAN", label: "Latvian" },
  { value: "LITHUANIAN", label: "Lithuanian" },
  { value: "MALAYSIAN", label: "Malaysian" },
  { value: "INDONESIAN", label: "Indonesian" },
  { value: "PHILIPPINE", label: "Philippine" },
];
export const externalBodyParts = [
  { label: "Head", value: "head" },
  { label: "Forehead", value: "forehead" },
  { label: "Eyes", value: "eyes" },
  { label: "Ears", value: "ears" },
  { label: "Nose", value: "nose" },
  { label: "Mouth", value: "mouth" },
  { label: "Chin", value: "chin" },
  { label: "Neck", value: "neck" },
  { label: "Shoulders", value: "shoulders" },
  { label: "Chest", value: "chest" },
  { label: "Back", value: "back" },
  { label: "Arms", value: "arms" },
  { label: "Elbows", value: "elbows" },
  { label: "Forearms", value: "forearms" },
  { label: "Wrists", value: "wrists" },
  { label: "Hands", value: "hands" },
  { label: "Fingers", value: "fingers" },
  { label: "Abdomen", value: "abdomen" },
  { label: "Waist", value: "waist" },
  { label: "Hips", value: "hips" },
  { label: "Thighs", value: "thighs" },
  { label: "Knees", value: "knees" },
  { label: "Calves", value: "calves" },
  { label: "Ankles", value: "ankles" },
  { label: "Feet", value: "feet" },
  { label: "Toes", value: "toes" },
];
