import { useState } from "react";
const TOKEN_KEY = "heal_jwt";

const useAuthToken = () => {
  const [token, setToken] = useState<string | null>(() => {
    const storedToken = sessionStorage.getItem(TOKEN_KEY);
    return storedToken || null;
  });

  const setAuthToken = (newToken: string | null) => {
    setToken(newToken);

    if (newToken) {
      sessionStorage.setItem(TOKEN_KEY, newToken);
    } else {
      sessionStorage.removeItem(TOKEN_KEY);
    }
  };

  return { token, setAuthToken };
};

export default useAuthToken;
