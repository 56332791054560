/* eslint-disable */
import AuthLayout from "../../Layouts/AuthLayout";
import Input from "../../Components/Forms/Input";
import { CreateAdminParams } from "../../config/types";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { signupSchema } from "../../config/validationSchema";
import { useNavigate } from "react-router-dom";
import useCreateAdmin from "../../Hooks/services/Admin/useCreateAdmin";
import { toast } from "react-toastify";
import SubmitButton from "../../Components/Common/SubmitButton";
import LocationInput from "../../Components/Forms/LocationInput";

const SignUp = () => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<CreateAdminParams>({
    resolver: yupResolver(signupSchema),
  });
  const { mutateAsync, isPending } = useCreateAdmin();
  const onSubmit: SubmitHandler<CreateAdminParams> = async (data: any) => {
    await mutateAsync(data)
      .then(() =>
        toast.success("The user was registered successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      )
      .catch(() =>
        toast.error("There was an error creating the user", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      );
  };
  return (
    <AuthLayout label="Admin Sign up" title="Admin Sign up">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-3/4 mt-4 mb-4 mx-auto justify-center"
      >
        <div className="flex justify-between">
          <Input<CreateAdminParams>
            name="first_name"
            label="First Name"
            control={control}
            placeholder="Enter your first name"
          />
          <Input<CreateAdminParams>
            name="last_name"
            label="Last Name"
            control={control}
            placeholder="Enter your last name"
          />
        </div>
        <Input<CreateAdminParams>
          name="email"
          label="Email"
          control={control}
          placeholder="Enter your email"
          type="email"
        />
        <Input<CreateAdminParams>
          name="phone_number"
          label="Phone Number"
          control={control}
          placeholder="Enter your phone number"
          type="text"
        />
        <Input<CreateAdminParams>
          name="website"
          label="Website"
          control={control}
          placeholder="Enter your website url"
          type="text"
        />
        <LocationInput<CreateAdminParams>
          name="location"
          label="Location"
          control={control}
          placeholder="Enter a location"
        />
        <Input<CreateAdminParams>
          name="password"
          label="Password"
          control={control}
          placeholder=""
          type="password"
        />
        <div className="flex flex-col items-center justify-center">
          <SubmitButton label="Sign up" isLoading={isPending} />
          <div onClick={() => navigate("/auth/login")} className="mt-4">
            <p className="text-black underline cursor-pointer">
              Already have an account? sign in.
            </p>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};

export default SignUp;
