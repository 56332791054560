import React, { useRef } from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import HeroSection from "./HeroSection";
import FeatureSection from "./FeatureSection";
import AboutUsSection from "./AboutUsSection";
import ContactUsSection from "./ContactUsSection";
export const HomePage = () => {
  const aboutRef = useRef<HTMLDivElement | null>(null);
  const contactRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = (section: "home" | "about" | "contact") => {
    if (section === "about" && aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "contact" && contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Navbar handleScroll={handleScroll} />
      <section id="home">
        <HeroSection />
        <FeatureSection />
      </section>
      <section ref={aboutRef} id="about">
        <AboutUsSection />
      </section>
      <section ref={contactRef} id="contact">
        <ContactUsSection />
      </section>
      <Footer />
    </>
  );
};
