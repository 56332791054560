/* eslint-disable */
import AuthLayout from "../../Layouts/AuthLayout";
import Input from "../../Components/Forms/Input";
import { CreateCarerParams } from "../../config/types";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import SubmitButton from "../../Components/Common/SubmitButton";
import DOBInput from "../../Components/Forms/DateOfBirthInput";
import useCreateCarer from "../../Hooks/services/Carers/useCreateCarer";
import LocationInput from "../../Components/Forms/LocationInput";
import { createCarerSchema } from "../../config/validationSchema";

const CreateCarer = () => {
  const { control, handleSubmit } = useForm<CreateCarerParams>({
    resolver: yupResolver(createCarerSchema),
  });
  const { mutateAsync, isPending } = useCreateCarer();
  const onSubmit: SubmitHandler<CreateCarerParams> = async (data: any) => {
    await mutateAsync(data)
      .then(() =>
        toast.success("The user was registered successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      )
      .catch(() =>
        toast.error("There was an error creating the user", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      );
  };
  return (
    <AuthLayout title="Welcome!" subTitle="Register with Us">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="md:w-3/4 w-full mt-4 mb-4 mx-auto justify-center"
      >
        <div className="flex space-x-4 justify-between">
          <Input<CreateCarerParams>
            name="first_name"
            label="First Name"
            control={control}
            placeholder="Enter your first name"
          />
          <Input<CreateCarerParams>
            name="last_name"
            label="Last Name"
            control={control}
            placeholder="Enter your last name"
          />
        </div>
        <Input<CreateCarerParams>
          name="email"
          label="Email"
          control={control}
          placeholder="Enter your email"
          type="email"
        />
        <DOBInput<CreateCarerParams>
          name="date_of_birth"
          label="Date of Birth"
          control={control}
          placeholder="YYYY-MM-DD"
        />
        <Input<CreateCarerParams>
          name="phone_number"
          label="Phone Number"
          control={control}
          placeholder="Enter your phone number"
          type="phone"
        />
        <LocationInput<CreateCarerParams>
          name="location"
          label="Location"
          control={control}
          placeholder="Enter a location"
        />
        <Input<CreateCarerParams>
          name="password"
          label="Password"
          control={control}
          placeholder=""
          type="password"
        />
        <div className="flex flex-col items-center justify-center">
          <SubmitButton label="Submit" isLoading={isPending} />
        </div>
      </form>
    </AuthLayout>
  );
};

export default CreateCarer;
