/* eslint-disable react/react-in-jsx-scope */
import BreadCrumbLayout from "../../Layouts/BreadCrumbLayout";
import HeaderLayout from "../../Layouts/HeaderLayout";

const InviteCarer = () => {
  return (
    <BreadCrumbLayout title="Invite Carer">
      <HeaderLayout title="Invite Carer">
        <p>Carer</p>
      </HeaderLayout>
    </BreadCrumbLayout>
  );
};

export default InviteCarer;
