/* eslint-disable */
import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import axiosInstance from "../../common/AxiosInstance";
import { CreateAppointmentParams } from "../../../config/types";

const createAppointment = async (data: CreateAppointmentParams) => {
  const location =
    data.location.postal_code === null ||
    data.location.street === null ||
    data.location.city === null
      ? (({ postal_code, street, city, ...rest }) => rest)(data.location)
      : { ...data.location };
  const newData = {
    ...data,
    admin: "34ac5386-cd8b-49b3-b251-5d4dee843aef",
    recurrence: "RRULE:FREQ=DAILY;INTERVAL=2;COUNT=8;",
    status: "Draft",
    location: location,
  };
  const response = await axiosInstance.post(`/booking/`, newData);

  return response;
};

const useCreateAppointment = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Response,
  CreateAppointmentParams
> => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createAppointment,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: ["appointments"] })
        .then(() => {
          navigate("/rota");
        })
        .catch((error: Error) => {
          throw new Error(error.message);
        });
    },
  });
};

export default useCreateAppointment;
