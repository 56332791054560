/* eslint-disable */

import React, { useState } from "react";
import { useController, Control, FieldValues, Path } from "react-hook-form";
import { useLoadScript, Autocomplete, Libraries } from "@react-google-maps/api";

interface LocationInputProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  placeholder?: string;
}

const libraries: Libraries = ["places"];

const LocationInput = <T extends FieldValues>({
  name,
  label,
  control,
  placeholder = "Enter a location",
}: LocationInputProps<T>) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "", // Ensure you have your API key
    libraries,
  });

  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const [inputValue, setInputValue] = useState("");

  const {
    field: { onChange, onBlur, ref, value },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const handlePlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();

      const addressComponents = place.address_components || [];
      const details = {
        latitude: place.geometry?.location?.lat(),
        longitude: place.geometry?.location?.lng(),
        postalCode: addressComponents.find((comp) =>
          comp.types.includes("postal_code"),
        )?.long_name,
        city: addressComponents.find((comp) => comp.types.includes("locality"))
          ?.long_name,
        street: addressComponents.find((comp) => comp.types.includes("route"))
          ?.long_name,
        country: addressComponents.find((comp) =>
          comp.types.includes("country"),
        )?.long_name,
        country_code: addressComponents.find((comp) =>
          comp.types.includes("country"),
        )?.short_name,
        formattedAddress: place.formatted_address,
      };

      onChange(details);
      setInputValue(place.formatted_address || "");
    }
  };

  return (
    <div className="mb-4">
      <label
        htmlFor={name}
        className="block text-black text-sm font-medium mb-2"
      >
        {label}
      </label>
      {isLoaded ? (
        <Autocomplete
          onLoad={(autocomplete) => setAutocomplete(autocomplete)}
          onPlaceChanged={handlePlaceChanged}
        >
          <input
            id={name}
            type="text"
            placeholder={placeholder}
            value={value?.formattedAddress || inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              onChange({ ...value, formattedAddress: e.target.value });
            }}
            onBlur={onBlur}
            ref={ref}
            className={`shadow appearance-none border border-borderGray rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline ${
              error ? "border-red-500" : ""
            }`}
          />
        </Autocomplete>
      ) : (
        <p>Loading...</p>
      )}
      {error && (
        <p className="text-red-500 text-xs italic mt-2">{error.message}</p>
      )}
    </div>
  );
};

export default LocationInput;
