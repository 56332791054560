/* eslint-disable */

import { AxiosResponse } from "axios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AvailabilityResponse } from "../../../config/types";
import axiosInstance from "../../common/AxiosInstance";

const fetchData = async (): Promise<AvailabilityResponse> => {
  try {
    const response: AxiosResponse<AvailabilityResponse> =
      await axiosInstance.get(
        `carer/availability/carer/?start_date=2024-08-08&end_date=2024-10-10`,
      );
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};

const useGetAvailability = (): UseQueryResult<AvailabilityResponse, Error> => {
  return useQuery<AvailabilityResponse, Error>({
    queryKey: ["availability"],
    queryFn: fetchData,
  });
};

export default useGetAvailability;
