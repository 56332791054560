/* eslint-disable */

import { useState } from "react";
import dayjs from "dayjs";
import BreadCrumbLayout from "../../Layouts/BreadCrumbLayout";
import HeaderLayout from "../../Layouts/HeaderLayout";
import { AnimatePresence } from "framer-motion";
import DialogLayout from "../../Layouts/DialogLayout";
import "dayjs/locale/en-gb";
import useGetAppointments from "../../Hooks/services/Appointments/useGetAppointment";
import Spinner from "../../Components/Common/Spinner";
import useGetCarers from "../../Hooks/services/Carers/useGetCarers";
import AppointmentDialog from "./AppointmentDialog";

dayjs.locale("en-gb");

const Rota: React.FC = () => {
  const [currentWeek, setCurrentWeek] = useState(dayjs());
  const [selectedShift, setSelectedShift] = useState<any>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { data: appointments, isLoading } = useGetAppointments({});
  const locations = Array.from(
    new Set(
      appointments?.results
        .filter((appointment) => appointment.location !== null)
        .map((appointment) => appointment.location.city),
    ),
  );
  const updatedData = appointments?.results.map((item) => {
    const [hours, minutes, seconds] = item.start_time.split(":").map(Number);
    const shiftType = hours < 12 ? "morning" : "afternoon";

    return { ...item, shift_type: shiftType };
  });
  const shiftTypes = Array.from(
    new Set(updatedData?.map((appointment) => appointment.shift_type)),
  );
  const goToPreviousWeek = () => {
    setCurrentWeek(currentWeek.subtract(1, "week"));
  };

  const goToNextWeek = () => {
    setCurrentWeek(currentWeek.add(1, "week"));
  };
  const handleShiftClick = (shift: any) => {
    setSelectedShift(shift);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setTimeout(() => setSelectedShift(null), 300);
  };
  const days = Array.from({ length: 7 }, (_, i) =>
    currentWeek.startOf("week").add(i, "day"),
  );
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <BreadCrumbLayout title="Rota">
      <HeaderLayout
        label="Create Appointment"
        link="/rota/create-appointment"
        title="Rota"
      >
        <div className="container">
          <div className="text-center p-4">
            <div className="flex justify-end items-center mb-8 space-x-4">
              <button
                className="bg-yellow-500 hover:bg-yellow-600 text-black px-6 py-2 rounded-lg shadow transition duration-200"
                onClick={goToPreviousWeek}
              >
                &larr; Previous
              </button>
              <h2 className="text-lg font-extrabold text-gray-700">
                {currentWeek.startOf("week").format("MMM D")} -{" "}
                {currentWeek.endOf("week").format("MMM D, YYYY")}
              </h2>
              <button
                className="bg-yellow-500 hover:bg-yellow-600 text-black px-6 py-2 rounded-lg shadow transition duration-200"
                onClick={goToNextWeek}
              >
                Next &rarr;
              </button>
            </div>

            <div className="grid grid-cols-9 gap-2">
              {/* Locations Column */}
              <div className="bg-yellow-500 text-black p-2 font-bold rounded-tl-lg">
                Location
              </div>
              {/* Shift Types Column */}
              <div className="bg-yellow-500 text-black p-2 font-bold rounded-tl-lg">
                Shift Type
              </div>
              {/* Day Names Columns */}
              {days.map((day) => (
                <div
                  key={day.format("YYYY-MM-DD")}
                  className="bg-yellow-500 text-black p-2 font-bold"
                >
                  {day.format("dddd")}
                </div>
              ))}

              {locations.map((location) =>
                shiftTypes.map((shiftType) => (
                  <>
                    <div
                      key={`${location}-${shiftType}-location`}
                      className="border border-yellow-500 p-2 font-bold"
                    >
                      {location}
                    </div>
                    <div
                      key={`${location}-${shiftType}-type`}
                      className="border border-yellow-500 p-2 font-bold"
                    >
                      {shiftType.charAt(0).toUpperCase() + shiftType.slice(1)}
                    </div>
                    {days.map((day) => {
                      const dayShifts = updatedData?.filter(
                        (s) =>
                          s.location !== null &&
                          s.shift_type === shiftType &&
                          s.location.city === location &&
                          dayjs(s.start_date).isSame(day, "day"),
                      );

                      return (
                        <div
                          key={`${location}-${shiftType}-${day.format("YYYY-MM-DD")}`}
                          className="border border-yellow-500 p-2 bg-gray-200 space-y-1"
                        >
                          {dayShifts !== undefined && dayShifts.length > 0 ? (
                            dayShifts?.map((shift) => (
                              <div
                                onClick={() => handleShiftClick(shift.uid)}
                                key={shift.uid}
                                className="bg-green-500 text-white p-1 rounded cursor-pointer"
                              >
                                <h1>
                                  {" "}
                                  {`${shift.patient.user.first_name} ${shift.patient.user.last_name}`}
                                </h1>
                                <p className="mt-2">
                                  {dayjs(
                                    `2024-01-01 ${shift.start_time}`,
                                    "YYYY-MM-DD HH:mm:ss",
                                  ).format("h:mm A")}{" "}
                                  -{" "}
                                  {dayjs(
                                    `2024-01-01 ${shift.end_time}`,
                                    "YYYY-MM-DD HH:mm:ss",
                                  ).format("h:mm A")}
                                </p>
                              </div>
                            ))
                          ) : (
                            <div>No Shifts</div>
                          )}
                        </div>
                      );
                    })}
                  </>
                )),
              )}
            </div>
          </div>
        </div>
        <AnimatePresence>
          {isDialogOpen && (
            <DialogLayout>
              <AppointmentDialog
                handleCloseDialog={handleCloseDialog}
                uid={selectedShift}
              />
            </DialogLayout>
          )}
        </AnimatePresence>
      </HeaderLayout>
    </BreadCrumbLayout>
  );
};

export default Rota;
