/* eslint-disable */

import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";
import axiosInstance from "../../common/AxiosInstance";
import { useNavigate } from "react-router-dom";

const deleteCarer = async (uid: string) => {
  const response = await axiosInstance.delete(`/carer/${uid}/`);
  return response.data; // Assuming you want to return the response data
};

const useDeleteCarer = (): UseMutationResult<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  Error,
  string
> => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: deleteCarer,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: ["carers"] })
        .then(() => navigate("/carers"))
        .catch((error: Error) => {
          console.error(
            "Error during query invalidation or navigation:",
            error.message,
          );
        });
    },
    onError: (error: Error) => {
      console.error("Error during deletion:", error.message);
    },
  });
};

export default useDeleteCarer;
