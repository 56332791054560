/* eslint-disable react/react-in-jsx-scope */
import { useController, Control, FieldValues, Path } from "react-hook-form";

interface SelectProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  options: { value: string; label: string }[];
  placeholder?: string;
  disabled?: boolean;
}

const Select = <T extends FieldValues>({
  name,
  label,
  control,
  options,
  placeholder = "",
  disabled = false,
}: SelectProps<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: undefined,
  });

  return (
    <div className="mb-4">
      <label
        htmlFor={name}
        className="block text-black text-sm font-medium mb-2"
      >
        {label}
      </label>
      <select
        id={name}
        disabled={disabled}
        {...field}
        className={`shadow appearance-none border border-borderGray rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline ${
          error ? "border-red-500" : ""
        }`}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <p className="text-red-500 text-xs  mt-2">{error.message}</p>}
    </div>
  );
};

export default Select;
