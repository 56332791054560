/* eslint-disable */

import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";
import axiosInstance from "../../common/AxiosInstance";
import { CreateCarerDocuments } from "../../../config/types";

const uploadCarerDocuments = async (data: CreateCarerDocuments) => {
  const { document: worker_document } = data;

  for (let i = 0; i < worker_document.length; i++) {
    const workerDocument = worker_document[i];

    const worker_documents = new FormData();

    if (workerDocument.file) {
      worker_documents.append("file", workerDocument.file); // Ensure 'file' is of type 'File' or 'Blob'
    } else {
      console.error("File is missing or not of correct type.");
    }

    if (workerDocument.expiry_date) {
      worker_documents.append("expiry_date", workerDocument.expiry_date);
    }

    worker_documents.append(
      "file_name",
      workerDocument.file_name === "Other"
        ? workerDocument.other_name
        : workerDocument.file_name,
    );
    worker_documents.append("file_type", workerDocument.file_type);

    worker_documents.append("document_type", workerDocument.document_type);
    worker_documents.append("carer", data.uid);

    try {
      await axiosInstance.post(`carer/documents/carer/`, worker_documents, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      // Log the error or handle it as required
      console.error("Error occurred while sending document:", error);
      throw error; // Re-throw error to handle it in mutation's onError
    }
  }
};

const useUploadCarerDocuments = (): UseMutationResult<
  any, // Replace with actual response type if possible
  Error, // Use Error type if the error is an instance of Error
  CreateCarerDocuments
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: uploadCarerDocuments,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: ["carerdocuments"] })
        .catch((error: Error) => {
          console.error("Error invalidating carer documents query:", error);
        });
    },
    onError: (error: Error) => {
      console.error("Mutation error:", error.message);
      // Handle mutation error, show notification, etc.
    },
  });
};

export default useUploadCarerDocuments;
