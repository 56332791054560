/* eslint-disable */

import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import axiosInstance from "../../common/AxiosInstance";
import { CreateCarerParams } from "../../../config/types";

const createCarer = async (data: CreateCarerParams) => {
  const location =
    data.location.postal_code === null ||
    data.location.street === null ||
    data.location.city === null
      ? (({ postal_code, street, city, ...rest }) => rest)(data.location)
      : { ...data.location };
  const newData = {
    ...data,
    admin: "34ac5386-cd8b-49b3-b251-5d4dee843aef",
    location: location,
  };
  const response = await axiosInstance.post(`/carer/`, newData);

  return response;
};

const useCreateCarer = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Response,
  CreateCarerParams
> => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createCarer,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: ["carers"] })
        .then(() => {
          navigate("/thank-you");
        })
        .catch((error: Error) => {
          throw new Error(error.message);
        });
    },
  });
};

export default useCreateCarer;
