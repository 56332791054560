/* eslint-disable */

import { ArrowRight } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const ContactUsSection: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-white px-8 md:px-16 py-16">
      <div className=" bg-doctorLittleGreen rounded-3xl p-8 text-white flex flex-col items-center">
        <h2 className="text-2xl font-semibold mb-4">Searching for a job?</h2>
        <p className="mb-6 text-sm font-normal max-w-3xl text-center">
          We offer flexible solutions, comprehensive training, and robust
          wellness support for roles from the community to the labs and
          practitioners. Whether you're a Community Health Worker (CHW) or a
          Specialist, join us to advance personalized care through innovative
          technology.
        </p>
        <div className="md:flex md:space-x-4">
          <button
            onClick={() => navigate("/jobs")}
            className=" bg-primary text-white font-medium text-sm py-2 px-6 rounded-full hover:bg-primary transition duration-300"
          >
            Searching for a job?
          </button>
          <button
            onClick={() => navigate("/carer/create-carer")}
            className="bg-transparent text-center  text-white font-semibold py-2 px-6 rounded-full hover:bg-white hover:text-green-500 transition duration-300 flex items-center"
          >
            Register
            <ArrowRight className="ml-2 h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactUsSection;
