// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}
.react-phone-number-input {
  border: 1px solid #e2e8f0;
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.react-phone-number-input__country {
  padding-right: 0.5rem;
}
.PhoneInputInput {
  border: 1px solid #8d8d8d;
  padding: 0.5rem;
  border-radius: 0.25rem;
}
.PhoneInputCountry {
  border: 1px solid #8d8d8d;
  padding: 0.5rem;
  border-radius: 0.25rem;
}
/* Hide the default radio button */

/* Style for checked radio buttons */
.radio-group input[type="radio"]:checked + label::before {
  background-color: yellow !important; /* Change this color for customization */
  border-color: black !important;
}

/* Create the inner dot for the selected state */
.radio-group input[type="radio"]:checked + label::after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: black; /* Inner dot color */
}
`, "",{"version":3,"sources":["webpack://./src/Components/Forms/styles.css"],"names":[],"mappings":"AAAA;;;;;;;;;EASE,WAAW;AACb;AACA;EACE,yBAAyB;EACzB,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;AACvB;AACA;EACE,yBAAyB;EACzB,eAAe;EACf,sBAAsB;AACxB;AACA;EACE,yBAAyB;EACzB,eAAe;EACf,sBAAsB;AACxB;AACA,kCAAkC;;AAElC,oCAAoC;AACpC;EACE,mCAAmC,EAAE,wCAAwC;EAC7E,8BAA8B;AAChC;;AAEA,gDAAgD;AAChD;EACE,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,uBAAuB,EAAE,oBAAoB;AAC/C","sourcesContent":[".customDatePickerWidth,\n.customDatePickerWidth > div.react-datepicker-wrapper,\n.customDatePickerWidth\n  > div\n  > div.react-datepicker__input-container\n  .customDatePickerWidth\n  > div\n  > div.react-datepicker__input-container\n  input {\n  width: 100%;\n}\n.react-phone-number-input {\n  border: 1px solid #e2e8f0;\n  padding: 0.5rem;\n  border-radius: 0.25rem;\n}\n\n.react-phone-number-input__country {\n  padding-right: 0.5rem;\n}\n.PhoneInputInput {\n  border: 1px solid #8d8d8d;\n  padding: 0.5rem;\n  border-radius: 0.25rem;\n}\n.PhoneInputCountry {\n  border: 1px solid #8d8d8d;\n  padding: 0.5rem;\n  border-radius: 0.25rem;\n}\n/* Hide the default radio button */\n\n/* Style for checked radio buttons */\n.radio-group input[type=\"radio\"]:checked + label::before {\n  background-color: yellow !important; /* Change this color for customization */\n  border-color: black !important;\n}\n\n/* Create the inner dot for the selected state */\n.radio-group input[type=\"radio\"]:checked + label::after {\n  content: \"\";\n  position: absolute;\n  top: 4px;\n  left: 4px;\n  width: 8px;\n  height: 8px;\n  border-radius: 50%;\n  background-color: black; /* Inner dot color */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
