/* eslint-disable */
import { AxiosResponse } from "axios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import axiosInstance from "../../common/AxiosInstance";
import { CarerApiResponse } from "../../../config/types";

interface CarerOptions {
  uid?: string;
  admin__uid?: string;
}

const fetchData = async (options: CarerOptions): Promise<CarerApiResponse> => {
  const { uid, admin__uid } = options;

  const queryParams = new URLSearchParams({
    limit: "100",
  });
  if (uid !== undefined && uid !== "") {
    queryParams.append("uid", uid);
  }
  if (admin__uid !== undefined && admin__uid !== "") {
    queryParams.append("admin__uid", admin__uid);
  }
  try {
    const response: AxiosResponse<CarerApiResponse> = await axiosInstance.get(
      `/carer/?${queryParams}`,
    );
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};

const useGetCarers = (
  options: CarerOptions,
): UseQueryResult<CarerApiResponse, Error> => {
  return useQuery<CarerApiResponse, Error>({
    queryKey: ["carers", options.uid, options.admin__uid],
    queryFn: () => fetchData(options),
  });
};

export default useGetCarers;
