/* eslint-disable react/react-in-jsx-scope */
import { useController, Control, FieldValues, Path } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./styles.css"; // Optional custom CSS for better styling control

interface InputProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
}

const Input = <T extends FieldValues>({
  name,
  label,
  control,
  placeholder = "",
  type = "text",
  disabled = false,
}: InputProps<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: undefined,
  });

  return (
    <div className="mb-4">
      <label
        htmlFor={name}
        className="block text-black text-sm font-medium mb-2"
      >
        {label}
      </label>
      {type === "phone" ? (
        <PhoneInput
          international
          defaultCountry="NG" // Set your default country code
          value={field.value}
          onChange={field.onChange}
          disabled={disabled}
        />
      ) : (
        <input
          id={name}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          {...field}
          className={`shadow appearance-none border border-borderGray rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline ${
            error ? "border-red-500" : ""
          }`}
        />
      )}
      {error && <p className="text-red-500 text-xs mt-2">{error.message}</p>}
    </div>
  );
};

export default Input;
