/* eslint-disable */

import { AxiosResponse } from "axios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import axiosInstance from "../../common/AxiosInstance";
import { ReportDetailsResponse } from "../../../config/types";

const fetchData = async (): Promise<ReportDetailsResponse> => {
  try {
    const response: AxiosResponse<ReportDetailsResponse> =
      await axiosInstance.get("booking/shift/report/");
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};

const useGetReports = (): UseQueryResult<ReportDetailsResponse, Error> => {
  return useQuery<ReportDetailsResponse, Error>({
    queryKey: ["reports"],
    queryFn: fetchData,
  });
};

export default useGetReports;
