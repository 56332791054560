/* eslint-disable */

import React from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import AuthLayout from "../../Layouts/AuthLayout";
import SubmitButton from "../../Components/Common/SubmitButton";
import useAddCarerWorkExperience from "../../Hooks/services/Carers/useAddWorkExperience";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface Experience {
  job_title: string;
  start_date: string;
  end_date: string;
  company: string;
  other_name: string;
}

interface MyFormValues {
  experience: Experience[]; // Array of Document objects
  carer: string;
}
const fileNameOptions = [
  { value: "Nurse", label: "Nurse Practioner" },
  { value: "Doctor", label: "Doctor License" },
  { value: "Other", label: "Other" },
];
type Props = {
  handleNextStep: () => void;
  handlePrevStep: () => void;
};
const WorkExperience = ({ handleNextStep, handlePrevStep }: Props) => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<MyFormValues>({
    defaultValues: {
      experience: [
        {
          job_title: "",
          start_date: "",
          end_date: "",
          company: "",
          other_name: "",
        },
      ],
    },
  });
  const { mutateAsync, isPending } = useAddCarerWorkExperience();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "experience",
  });
  const selectedFileNames = watch("experience") || [];

  const onSubmit = async (data: MyFormValues) => {
    const post = { ...data, carer: params.id || "" };
    await mutateAsync(post)
      .then(() => handleNextStep())
      .catch(() =>
        toast.error("There was an error adding the details", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      );
  };

  return (
    <AuthLayout
      handlePrevStep={handlePrevStep}
      title="Add Your Work Experience"
      other
      label="Provide details about your relevant work experience"
    >
      <form
        className="md:w-3/4 w-full mt-4 mb-4 mx-auto justify-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        {fields.map((field, index) => (
          <div key={field.id}>
            <Controller
              control={control}
              name={`experience.${index}.company`}
              render={({ field }) => (
                <div className="mb-4">
                  <label
                    htmlFor="file-name"
                    className="block text-black text-sm font-medium mb-2"
                  >
                    Company Name
                  </label>
                  <input
                    {...field}
                    placeholder="Enter company name"
                    className={`shadow appearance-none border border-borderGray rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline${
                      errors.experience?.[index]?.company
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                </div>
              )}
            />
            {/* You can manage the error messages directly here if needed */}
            {errors.experience?.[index]?.company && (
              <p className="text-red-500">company is required</p>
            )}
            <Controller
              control={control}
              name={`experience.${index}.job_title`}
              render={({ field }) => (
                <div className="mb-4">
                  <label
                    htmlFor="job-title"
                    className="block text-black text-sm font-medium mb-2"
                  >
                    Job Title
                  </label>
                  <select
                    {...field}
                    className={`shadow appearance-none border border-borderGray rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline${
                      errors.experience?.[index]?.job_title
                        ? "border-red-500"
                        : ""
                    }`}
                  >
                    <option value="" disabled>
                      Select a role
                    </option>
                    {fileNameOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            />
            {/* You can manage the error messages directly here if needed */}
            {errors.experience?.[index]?.job_title && (
              <p className="text-red-500">Role name is required</p>
            )}
            {selectedFileNames[index]?.job_title === "Other" && (
              <>
                <Controller
                  control={control}
                  name={`experience.${index}.other_name`}
                  render={({ field }) => (
                    <div className="mb-4">
                      <label
                        htmlFor="file-name"
                        className="block text-black text-sm font-medium mb-2"
                      >
                        Enter the name of the other job title
                      </label>
                      <input
                        {...field}
                        placeholder="Enter role name"
                        className={`shadow appearance-none border border-borderGray rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline ${
                          errors.experience?.[index]?.other_name
                            ? "border-red-500"
                            : ""
                        }`}
                      />
                    </div>
                  )}
                />
                {/* You can manage the error messages directly here if needed */}
                {errors.experience?.[index]?.other_name && (
                  <p className="text-red-500">Role is required</p>
                )}
              </>
            )}
            <Controller
              control={control}
              name={`experience.${index}.start_date`}
              render={({ field }) => (
                <div className="mb-4">
                  <label
                    htmlFor="file-name"
                    className="block text-black text-sm font-medium mb-2"
                  >
                    Employment start date
                  </label>
                  <input
                    {...field}
                    type="date"
                    placeholder="Enter the start date"
                    className={`shadow appearance-none border border-borderGray rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline ${
                      errors.experience?.[index]?.start_date
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                </div>
              )}
            />
            {/* You can manage the error messages directly here if needed */}
            {errors.experience?.[index]?.start_date && (
              <p className="text-red-500">Start date is required</p>
            )}
            <Controller
              control={control}
              name={`experience.${index}.end_date`}
              render={({ field }) => (
                <div className="mb-4">
                  <label
                    htmlFor="file-name"
                    className="block text-black text-sm font-medium mb-2"
                  >
                    Employment end date
                  </label>
                  <input
                    {...field}
                    type="date"
                    placeholder="Enter the end date"
                    className={`shadow appearance-none border border-borderGray rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline${
                      errors.experience?.[index]?.end_date
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                </div>
              )}
            />

            {/* You can manage the error messages directly here if needed */}
            {errors.experience?.[index]?.end_date && (
              <p className="text-red-500">End date is required</p>
            )}
            {fields.length > 1 && (
              <button
                type="button"
                onClick={() => remove(index)}
                className="px-4 py-2 w-full mt-2 mb-2 border border-red-600 text-red-600 bg-transparent rounded-lg "
              >
                Remove Experience
              </button>
            )}
          </div>
        ))}

        <button
          type="button"
          onClick={() =>
            append({
              job_title: "",
              start_date: "",
              end_date: "",
              company: "",
              other_name: "",
            })
          }
          className="px-4 py-2 bg-transparent rounded-lg text-primary w-full border border-primary mt-2 "
        >
          + Add Experience
        </button>

        <div className="flex flex-col items-center justify-center">
          <SubmitButton label="Submit" isLoading={isPending} />
        </div>
      </form>
    </AuthLayout>
  );
};

export default WorkExperience;
