/* eslint-disable react/react-in-jsx-scope */
import { Route, Routes } from "react-router-dom";
import Login from "../Pages/Auth/Login";
import ProtectedRoutes from "./ProtectedRoutes";
import SignUp from "../Pages/Auth/SignUp";
import ThankYouForRegistering from "../Components/Common/ThankYouForRegistering";
import CreateCarer from "../Pages/Carers/CreateCarer";
import CreatePatient from "../Pages/Patients/CreatePatient";
import BankDetails from "../Pages/Carers/BankDetails";
import ContactInformation from "../Pages/Carers/ContactInformation";
import WorkExperience from "../Pages/Carers/WorkExperience";
import Documents from "../Pages/Carers/Documents";
import WorkDetails from "../Pages/Carers/WorkDetails";
import IdentificationDocuments from "../Pages/Carers/Documents";
import TrainingDocuments from "../Pages/Carers/Trainings";
import ThankYou from "../Components/Common/ThankYou";
import VerifyEmail from "../Pages/Auth/VerifyEmail";
import SymptomsChecker from "../Pages/Patients/SymptomsChecker";
import MedicalHistory from "../Pages/Patients/MedicalHistory";
import { HomePage } from "../Pages/Landing/Home";
import CarerInformation from "../Pages/Carers/CarerInformation";
import JobListing from "../Pages/Landing/JobListing";
import JobPosting from "../Pages/Landing/JobPosting";

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/auth/login" element={<Login />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/jobs" element={<JobListing />} />
        <Route path="/jobs/posting" element={<JobPosting />} />
        <Route path="/auth/signup" element={<SignUp />} />
        <Route path="/thank-you" element={<ThankYouForRegistering />} />
        <Route path="/thanks" element={<ThankYou />} />
        <Route path="/carer/create-carer" element={<CreateCarer />} />
        <Route
          path="/carers/contact-information/:id"
          element={<CarerInformation />}
        />

        <Route path="/carers/bank-details/:id" element={<BankDetails />} />

        <Route path="/verify-email/:token/:id" element={<VerifyEmail />} />

        <Route path="/patient/create-patient" element={<CreatePatient />} />
        <Route
          path="/patient/symptoms-checker/:id"
          element={<SymptomsChecker />}
        />
        <Route
          path="/patient/medical-history/:id"
          element={<MedicalHistory />}
        />
      </Routes>
      <ProtectedRoutes />
    </>
  );
};

export default AppRoutes;
