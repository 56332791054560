import "./App.css";
/* eslint-disable react/react-in-jsx-scope */
import Navbar from "./Components/Navbar/Navbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRoutes from "./Routes";
import useAuthToken from "./Hooks/common/useAuthToken";

function App() {
  const { token } = useAuthToken();
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        className="w-1/2"
      />
      {token !== null && <Navbar />}
      <AppRoutes />
    </>
  );
}

export default App;
