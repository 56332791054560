/* eslint-disable */
import BreadCrumbLayout from "../../Layouts/BreadCrumbLayout";
import HeaderLayout from "../../Layouts/HeaderLayout";
import Input from "../../Components/Forms/Input";
import SubmitButton from "../../Components/Common/SubmitButton";
import { CreateAdminUserParams } from "../../config/types";
import { createUserSchema } from "../../config/validationSchema";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useCreateAdminUsers from "../../Hooks/services/Admin/useCreateAdminUsers";
import { toast } from "react-toastify";
import DOBInput from "../../Components/Forms/DateOfBirthInput";

const CreateUser = () => {
  const { control, handleSubmit } = useForm<CreateAdminUserParams>({
    resolver: yupResolver(createUserSchema),
  });
  const { mutateAsync, isPending } = useCreateAdminUsers();
  const onSubmit: SubmitHandler<CreateAdminUserParams> = async (data: any) => {
    await mutateAsync(data)
      .then(() =>
        toast.success("The user was registered successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      )
      .catch(() =>
        toast.error("There was an error creating the user", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      );
  };
  return (
    <BreadCrumbLayout title="Create User">
      <HeaderLayout title="Create User">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-5/12 mt-4 mb-4 mx-auto  "
        >
          <Input<CreateAdminUserParams>
            name="first_name"
            label="First Name"
            control={control}
            placeholder="Enter your first name"
          />
          <Input<CreateAdminUserParams>
            name="last_name"
            label="Last Name"
            control={control}
            placeholder="Enter your last name"
          />
          <Input<CreateAdminUserParams>
            name="email"
            label="Email"
            control={control}
            placeholder="Enter your email"
            type="email"
          />
          <DOBInput<CreateAdminUserParams>
            name="date_of_birth"
            label="Date of Birth"
            control={control}
            placeholder="YYYY-MM-DD"
          />
          <Input<CreateAdminUserParams>
            name="phone_number"
            label="Phone Number"
            control={control}
            placeholder="Enter your phone number"
            type="text"
          />
          <Input<CreateAdminUserParams>
            name="password"
            label="Password"
            control={control}
            placeholder=""
            type="password"
          />
          <div className="flex flex-col items-center justify-center">
            <SubmitButton label="Submit" isLoading={isPending} />
          </div>
        </form>
      </HeaderLayout>
    </BreadCrumbLayout>
  );
};

export default CreateUser;
