/* eslint-disable */

import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";
import axiosInstance from "../../common/AxiosInstance";
import { useNavigate } from "react-router-dom";

const deleteAdminUser = async (uid: string) => {
  const response = await axiosInstance.delete(
    `/portal/portal-users/users/${uid}/`,
  );
  /* eslint-disable @typescript-eslint/no-unsafe-argument */
  return response.data;
};

const useDeleteAdminUser = (): UseMutationResult<any, Error, string> => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: deleteAdminUser,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: ["users"] })
        .then(() => navigate("/users"))
        .catch((error: Error) => {
          console.error(
            "Error during query invalidation or navigation:",
            error.message,
          );
        });
    },
    onError: (error: Error) => {
      console.error("Error during deletion:", error.message);
    },
  });
};

export default useDeleteAdminUser;
