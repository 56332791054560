/* eslint-disable */

import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";

import axiosInstance from "../../common/AxiosInstance";
import { CreatePatientMedicalHistory } from "../../../config/types";
import { arrayToCommaSeparatedString } from "../../../config/utils";

const addPatientMedicalHistory = async (data: CreatePatientMedicalHistory) => {
  const post = {
    current_medications: arrayToCommaSeparatedString(data.current_medications),
    existing_conditions: arrayToCommaSeparatedString(data.existing_conditions),
    allergies: arrayToCommaSeparatedString(data.allergies),
    family_history: arrayToCommaSeparatedString(data.family_history),
    patient_uid: data.patient_uid,
  };
  const response = await axiosInstance.post(
    `patient/medical_history/patient/`,
    post,
  );

  return response;
};

const useAddPatientMedicalHistory = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Response,
  CreatePatientMedicalHistory
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addPatientMedicalHistory,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: ["patientmedicalhistory"] })
        .catch((error: Error) => {
          throw new Error(error.message);
        });
    },
  });
};

export default useAddPatientMedicalHistory;
