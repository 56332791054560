import React, { useState } from "react";
import ProgressBar from "../../Components/Common/ProgressBar";
import ContactInformation from "./ContactInformation";
import WorkDetails from "./WorkDetails";
import WorkExperience from "./WorkExperience";
import IdentificationDocuments from "./Documents";
import TrainingDocuments from "./Trainings";

const CarerInformation = () => {
  const [step, setStep] = useState<number>(0);
  const totalSteps = 5;
  const handleNextStep = () => {
    setStep((prev) => Math.min(prev + 1, totalSteps - 1));
  };
  const handlePrevStep = () => {
    setStep((prev) => Math.max(prev - 1, 0));
  };
  const renderStep = () => {
    switch (step) {
      case 0:
        return <ContactInformation handleNextStep={handleNextStep} />;
      case 1:
        return (
          <WorkDetails
            handleNextStep={handleNextStep}
            handlePrevStep={handlePrevStep}
          />
        );
      case 2:
        return (
          <WorkExperience
            handleNextStep={handleNextStep}
            handlePrevStep={handlePrevStep}
          />
        );
      case 3:
        return (
          <IdentificationDocuments
            handleNextStep={handleNextStep}
            handlePrevStep={handlePrevStep}
          />
        );
      case 4:
        return (
          <TrainingDocuments
            handleNextStep={handleNextStep}
            handlePrevStep={handlePrevStep}
          />
        );
    }
  };
  return (
    <>
      <div className="flex mx-auto bg-white mt-12  w-9/12 ">
        <ProgressBar currentStep={step} />
      </div>
      {renderStep()}
    </>
  );
};

export default CarerInformation;
