/* eslint-disable */

import Upload from "../../assets/upload.png";

import React, { useState } from "react";
import { File, X } from "lucide-react";

const DocumentComponentUpload = ({ onFileSelect }: any) => {
  const [dragActive, setDragActive] = useState(false);
  const [file, setFile] = useState<any>(null);

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };

  const handleFile = (selectedFile: any) => {
    setFile(selectedFile);
    onFileSelect(selectedFile);
  };

  const removeFile = () => {
    setFile(null);
    onFileSelect(null);
  };

  return (
    <div className="w-full  mx-auto">
      <h2 className="text-sm font-medium mb-4">Upload Document</h2>
      <div
        className={`border-2 border-dashed rounded-lg p-8 text-center ${
          dragActive ? "border-blue-500 bg-blue-50" : "border-gray-300"
        }`}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        {!file ? (
          <>
            <div className="flex mb-4 justify-center">
              <img src={Upload} alt="Upload" className="h-fit w-fit" />
            </div>

            <p className="text-sm text-gray-600 mb-2">
              Select your file or drag and drop
            </p>
            <p className="text-xs text-gray-400 mb-4">
              PNG, JPG, PDF, DOCX (max. 5MB)
            </p>
            <label className="bg-orange-400 text-white px-4 py-2 rounded-full cursor-pointer hover:bg-orange-500 transition-colors">
              Browse
              <input
                type="file"
                className="hidden"
                onChange={handleChange}
                accept=".png,.jpg,.pdf,.docx"
              />
            </label>
          </>
        ) : (
          <div className="flex items-center justify-between bg-gray-100 p-4 rounded-lg">
            <div className="flex items-center">
              <File className="text-blue-500 mr-3" size={24} />
              <span className="text-sm font-medium text-gray-700">
                {file?.name}
              </span>
            </div>
            <button
              onClick={removeFile}
              className="text-red-500 hover:text-red-700"
              aria-label="Remove file"
            >
              <X size={20} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentComponentUpload;
