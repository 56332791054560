/* eslint-disable */
import React from "react";
import useGetCarerWorkExperience from "../../Hooks/services/Carers/useGetWorkExperience";
import Spinner from "../../Components/Common/Spinner";

type Props = {
  id: string;
};
const ViewWorkExperienceInformation = ({ id }: Props) => {
  const { data: experience, isLoading } = useGetCarerWorkExperience({
    uid: id,
  });
  if (isLoading) return <Spinner />;
  return (
    <div className="overflow-x-auto p-4">
      <table className="min-w-full bg-white border border-lightGray">
        <thead className=" bg-darkGray">
          <tr>
            <th className="py-2 px-4 border-b text-left border-lightGray">
              Company Name
            </th>
            <th className="py-2 px-4 border-b text-left border-lightGray">
              Role
            </th>
            <th className="py-2 px-4 border-b text-left border-lightGray">
              Start Date
            </th>
            <th className="py-2 px-4 border-b text-left border-lightGray">
              End Date
            </th>
          </tr>
        </thead>
        <tbody>
          {experience?.results.map((experience) => (
            <tr key={experience.uid} className="hover:bg-darkGray">
              <td className="py-2 px-4 border-b border-lightGray">
                {experience.company}
              </td>
              <td className="py-2 px-4 border-b border-lightGray">
                {experience.job_title}
              </td>
              <td className="py-2 px-4 border-b border-lightGray">
                {experience.start_date}
              </td>
              <td className="py-2 px-4 border-b border-lightGray">
                {experience.end_date}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewWorkExperienceInformation;
