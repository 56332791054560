/* eslint-disable */

import { useEffect, useState } from "react";
import Input from "../../Components/Forms/Input";
import { useForm, SubmitHandler } from "react-hook-form";
import { createPatientsMedicalHistorySchema } from "../../config/validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import Spinner from "../../Components/Common/Spinner";
import useUpdateCarer from "../../Hooks/services/Carers/useUpdateCarer";
import { toast } from "react-toastify";
import SubmitSpinner from "../../Components/Common/SubmitSpinner";
import useGetCarerContactDetails from "../../Hooks/services/Carers/useGetContactDetails";
import useGetPatientSymptomDetails from "../../Hooks/services/Patients/useGetPatientSymptoms";
import { CreatePatientMedicalHistory } from "../../config/types";
import Select from "../../Components/Forms/Select";
import Textarea from "../../Components/Forms/TextArea";
import {
  commaSeparatedStringToArray,
  externalBodyParts,
  painRates,
} from "../../config/utils";
import useGetPatientMedicalHistory from "../../Hooks/services/Patients/useGetPatientMedicalHistory";
import MultiItemInput from "../../Components/Forms/MultiInput";
import useUpdatePatientMedicalHistory from "../../Hooks/services/Patients/useUpdatePatientMedicalHistory";
type Props = {
  id: string;
};
const ViewMedicalHistory = ({ id }: Props) => {
  const params = useParams<{ id: string }>();
  const [initialValues, setInitialValues] =
    useState<CreatePatientMedicalHistory | null>(null);

  const { data: medical, isLoading } = useGetPatientMedicalHistory({
    uid: id,
  });

  const { control, handleSubmit, reset } = useForm<CreatePatientMedicalHistory>(
    {
      resolver: yupResolver(createPatientsMedicalHistorySchema),
      defaultValues: initialValues || {},
    },
  );

  const { mutateAsync: updatingMutation, isPending: updatePending } =
    useUpdatePatientMedicalHistory();

  const medicalHistory = medical?.results[0];

  useEffect(() => {
    if (medicalHistory) {
      setInitialValues({
        allergies: commaSeparatedStringToArray(medicalHistory.allergies) || [],
        current_medications:
          commaSeparatedStringToArray(medicalHistory.current_medications) || [],
        existing_conditions:
          commaSeparatedStringToArray(medicalHistory.existing_conditions) || [],
        family_history:
          commaSeparatedStringToArray(medicalHistory.family_history) || [],
      });
    }
  }, [medicalHistory]);

  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  }, [initialValues, reset]);
  console.log(initialValues);
  const onSubmit: SubmitHandler<CreatePatientMedicalHistory> = async (
    data: any,
  ) => {
    const newData = {
      ...data,
      patient_uid: medicalHistory?.uid || "",
    };
    await updatingMutation(newData)
      .then(() =>
        toast.success("The medical history was updated successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      )
      .catch(() =>
        toast.error("There was an error updating the medical history", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      );
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <form className="w-3/4 mt-4 mb-4  " onSubmit={handleSubmit(onSubmit)}>
      <div className="flex w-full justify-between">
        <div className="flex space-x-4 justify-between">
          <MultiItemInput
            name="existing_conditions"
            label="Existing Conditions"
            control={control}
            placeholder="Add existing conditions and press Enter"
          />
          <MultiItemInput
            name="current_medications"
            label="Current Medications"
            control={control}
            placeholder="Add current medications and press Enter"
          />
        </div>
        <div className="flex space-x-4 justify-between">
          <MultiItemInput
            name="allergies"
            label="Allergies"
            control={control}
            placeholder="Add allergies and press Enter"
          />
          <MultiItemInput
            name="family_history"
            label="Family History"
            control={control}
            placeholder="Add family history and press Enter"
          />
        </div>
      </div>

      <div className="flex  w-full justify-end">
        <div className="w-1/4 flex space-x-4 justify-end">
          <button
            className={`${updatePending ? "bg-black text-white" : " bg-primary text-white"} px-6 w-full py-2  rounded-lg  font-semibold`}
          >
            {updatePending ? <SubmitSpinner /> : "Update"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ViewMedicalHistory;
