/* eslint-disable */

import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";

import axiosInstance from "../../common/AxiosInstance";
import { CreateCarerParams } from "../../../config/types";

const updatePatient = async (data: CreateCarerParams) => {
  const response = await axiosInstance.patch(`/patient/${data.uid}/`, data);

  return response;
};

const useUpdatePatient = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Response,
  CreateCarerParams
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updatePatient,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: ["patients"] })
        .catch((error: Error) => {
          throw new Error(error.message);
        });
    },
  });
};

export default useUpdatePatient;
