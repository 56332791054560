/* eslint-disable */

import { useEffect, useState } from "react";
import BreadCrumbLayout from "../../Layouts/BreadCrumbLayout";
import HeaderLayout from "../../Layouts/HeaderLayout";
import CollapsibleContainer from "../../Layouts/CollapsibleLayout";
import Input from "../../Components/Forms/Input";
import { useForm, SubmitHandler } from "react-hook-form";
import { UpdatePatientParams } from "../../config/types";
import { updatePatientSchema } from "../../config/validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import DOBInput from "../../Components/Forms/DateOfBirthInput";
import { useParams } from "react-router-dom";
import Spinner from "../../Components/Common/Spinner";
import { toast } from "react-toastify";
import SubmitSpinner from "../../Components/Common/SubmitSpinner";
import useGetAdminUsers from "../../Hooks/services/Admin/useGetAdminUsers";
import useUpdateAdminUsers from "../../Hooks/services/Admin/useUpdateUser";
import useDeleteAdminUser from "../../Hooks/services/Admin/useDeleteUser";

const ViewSingleUser = () => {
  const params = useParams<{ id: string }>();
  const [initialValues, setInitialValues] =
    useState<UpdatePatientParams | null>(null);

  const { control, handleSubmit, reset } = useForm<UpdatePatientParams>({
    resolver: yupResolver(updatePatientSchema),
    defaultValues: initialValues || {},
  });

  const { data: carers, isLoading } = useGetAdminUsers({
    uid: params.id || "",
  });
  const { mutateAsync: updatingMutation, isPending: updatePending } =
    useUpdateAdminUsers();
  const { mutateAsync: deletingMutation, isPending: deletePending } =
    useDeleteAdminUser();

  useEffect(() => {
    const carer = carers?.results[0];
    if (carer) {
      setInitialValues({
        first_name: carer.user.first_name || "",
        last_name: carer.user.last_name || "",
        email: carer.user.email || "",
        phone_number: carer.phone_number || "",
        date_of_birth: carer.date_of_birth || "",
        gender: "",
      });
    }
  }, [carers]);

  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  }, [initialValues, reset]);
  const handleDelete = async () => {
    await deletingMutation(params.id || "")
      .then(() =>
        toast.success("The patient was deleted successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      )
      .catch(() =>
        toast.error("There was an error deleting the patient", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      );
  };

  const onSubmit: SubmitHandler<UpdatePatientParams> = async (data: any) => {
    const newData = {
      ...data,
      admin: "34ac5386-cd8b-49b3-b251-5d4dee843aef",
      location: "ee384b32-4758-4443-b207-a8fc35eb4ea9",
      uid: params.id || "",
    };
    await updatingMutation(newData)
      .then(() =>
        toast.success("The user was updated successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      )
      .catch(() =>
        toast.error("There was an error updating the user", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      );
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <BreadCrumbLayout title="Carers">
      <HeaderLayout title="Carers">
        <CollapsibleContainer title="Contact Information">
          <form onSubmit={handleSubmit(onSubmit)} className="w-3/4 mt-4 mb-4">
            <div className="flex w-full justify-between">
              <div className="flex space-x-4 justify-between">
                <Input<UpdatePatientParams>
                  name="first_name"
                  label="First Name"
                  control={control}
                  placeholder="Enter your first name"
                />
                <Input<UpdatePatientParams>
                  name="last_name"
                  label="Last Name"
                  control={control}
                  placeholder="Enter your last name"
                />
              </div>
              <div className="flex space-x-4 justify-between">
                <Input<UpdatePatientParams>
                  name="email"
                  label="Email"
                  control={control}
                  placeholder="Enter your email"
                  type="email"
                />
                <Input<UpdatePatientParams>
                  name="phone_number"
                  label="Phone Number"
                  control={control}
                  placeholder="Enter your phone number"
                  type="text"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex space-x-4 justify-between">
                <DOBInput<UpdatePatientParams>
                  name="date_of_birth"
                  label="Date of Birth"
                  control={control}
                  placeholder="YYYY-MM-DD"
                />
              </div>
            </div>
            <div className="flex  w-full justify-end">
              <div className="w-1/4 flex space-x-4 justify-end">
                <button
                  className={`${updatePending ? "bg-black text-white" : " bg-primary text-white"} px-6 w-full py-2  rounded-lg  font-semibold`}
                >
                  {updatePending ? <SubmitSpinner /> : "Update"}
                </button>
                <button
                  type="button"
                  onClick={handleDelete}
                  className={`${updatePending ? "bg-primary text-white" : "bg-black text-white "} px-6 w-full py-2  rounded-lg  font-semibold`}
                >
                  {deletePending ? <SubmitSpinner /> : "Delete"}
                </button>
              </div>
            </div>
          </form>
        </CollapsibleContainer>
      </HeaderLayout>
    </BreadCrumbLayout>
  );
};

export default ViewSingleUser;
