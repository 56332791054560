/* eslint-disable */
import useGetAppointments from "../../Hooks/services/Appointments/useGetAppointment";
import Spinner from "../../Components/Common/Spinner";
import dayjs from "dayjs";
import useGetCarers from "../../Hooks/services/Carers/useGetCarers";
import { useState } from "react";
import useCreateAppointmentAllocation from "../../Hooks/services/Appointments/useCreateAppointmentAllocation";
import SubmitSpinner from "../../Components/Common/SubmitSpinner";
import { toast } from "react-toastify";
import useGetAppointmentsAllocations from "../../Hooks/services/Appointments/useGetAppointmentAllocations";
import useGetAvailability from "../../Hooks/services/Carers/useGetAvailability";
import { AnimatePresence } from "framer-motion";
import DialogLayout from "../../Layouts/DialogLayout";
import CarerDetails from "./CarerDetails";
import useGetDailyAppointments from "../../Hooks/services/Appointments/useGetDailyAppointments";
import ReportDetails from "./ReportPage";
type Props = {
  uid: string;
  handleCloseDialog: () => void;
};
const AppointmentDialog = ({ uid, handleCloseDialog }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [selectedCarer, setSelectedCarer] = useState<any>(null);
  const [selectedShiftId, setSelectedShiftId] = useState<any>(null);
  const { data: appointments, isLoading } = useGetAppointments({ uid: uid });
  const { data: carers, isLoading: carersLoading } = useGetCarers({});
  const { data: availability, isLoading: availabilityLoading } =
    useGetAvailability();
  const { data: dailyAppointments, isLoading: dailyAppointmentsLoading } =
    useGetDailyAppointments();
  const { data: appointmentsAllocations, isLoading: allocationsLoading } =
    useGetAppointmentsAllocations();
  const { mutateAsync, isPending } = useCreateAppointmentAllocation();
  const selectedShift = appointments?.results[0];
  const assignedAppointments = appointmentsAllocations?.results.filter(
    (appointment) => appointment.booking.uid === uid,
  );
  const filteredDailyAppointments = dailyAppointments?.results.filter(
    (item) => item.allocation.booking.uid === uid,
  );
  console.log(assignedAppointments);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const handleCloseCarerDialog = () => {
    setIsDialogOpen(false);
    setTimeout(() => setSelectedCarer(null), 300);
  };
  const handleSelectUser = (id: string) => {
    setSelectedUserId(id);
  };
  const checkAvailability = (id: string) => {
    const filteredAvailability = availability?.results.filter(
      (item) =>
        item.carer === id &&
        item.occurrences.filter(
          (occurrence) =>
            dayjs(occurrence).isAfter(dayjs(item.start_date)) ||
            dayjs(occurrence) === dayjs(item.start_date),
        ),
    );
    const isOccurrenceMatched =
      filteredAvailability !== undefined &&
      filteredAvailability[0].occurrences.some((occurrence) => {
        const occurrenceDate = dayjs(occurrence).format("YYYY-MM-DD");
        return (
          occurrenceDate ===
          dayjs(selectedShift?.start_date).format("YYYY-MM-DD")
        );
      });

    return isOccurrenceMatched;
  };

  const handleAllocateCarer = async () => {
    const post = {
      status: "ASSIGNED",
      carer: selectedUserId || "",
      booking: uid,
    };
    await mutateAsync(post)
      .then(() =>
        toast.success("The appointment was assigned to carer successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      )
      .catch(() =>
        toast.error("There was an error assigning the appointment", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      );
  };
  if (
    isLoading ||
    carersLoading ||
    allocationsLoading ||
    availabilityLoading ||
    dailyAppointmentsLoading
  ) {
    return <Spinner />;
  }
  return (
    <>
      <div className="bg-lightGray p-4 border-b">
        <div className="flex justify-between  items-center ">
          <h3 className="text-lg font-medium">Appointment Details</h3>
          <button
            onClick={handleCloseDialog}
            className="text-xl bg-darkGray rounded-full h-8 w-8 text-center shadow-xl font-bold"
          >
            &times;
          </button>
        </div>
        <h1 className="text-black mt-4 font-bold text-lg">
          {dayjs(selectedShift?.start_date).format("dddd DD MMMM YYYY")}
        </h1>
        <h1 className="text-black mt-2 font-normal text-lg">
          {`${selectedShift?.patient.user.first_name} ${selectedShift?.patient.user.last_name}`}
        </h1>
        <div className="flex space-x-2 mt-2 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
            />
          </svg>
          <h2 className="text-lg font-normal text-black">
            {`${selectedShift?.location.city} ${selectedShift?.location.country.name}`}
          </h2>
        </div>
      </div>

      <div className="overflow-x-auto p-4">
        <h1 className="mb-3 font-medium">
          {assignedAppointments !== undefined && assignedAppointments.length > 0
            ? "Assigned Carer"
            : "Assign to carer"}
        </h1>
        {assignedAppointments !== undefined &&
        assignedAppointments.length > 0 ? (
          <table className="min-w-full bg-white border border-black">
            <thead className="bg-yellow-400">
              <tr>
                <th className="py-2 px-4 border-b border-black">Carer Name</th>
                <th className="py-2 px-4 border-b border-black">
                  Phone Number
                </th>
                <th className="py-2 px-4 border-b border-black">Location</th>
              </tr>
            </thead>
            <tbody>
              {assignedAppointments.map((carer) => (
                <tr key={carer.uid} className="hover:bg-yellow-100 ">
                  <td
                    onClick={() => {
                      setSelectedCarer(carer.carer.uid);
                      setIsDialogOpen(true);
                    }}
                    className="py-2 px-4 border-b cursor-pointer border-black"
                  >{`${carer.carer.user.first_name} ${carer.carer.user.last_name}`}</td>
                  <td className="py-2 px-4 border-b border-black">
                    {carer.carer.phone_number}
                  </td>
                  <td className="py-2 px-4 border-b border-black">
                    {`${carer.carer.location.city} ${carer.carer.location.country.name}`}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <table className="min-w-full bg-white border border-black">
            <thead className="bg-yellow-400">
              <tr>
                <th className="py-2 px-4 border-b border-black">Carer Name</th>
                <th className="py-2 px-4 border-b border-black">
                  Phone Number
                </th>
                <th className="py-2 px-4 border-b border-black">Location</th>
                <th className="py-2 px-4 border-b border-black">
                  Availability
                </th>
                <th className="py-2 px-4 border-b border-black">Actions</th>
              </tr>
            </thead>
            <tbody>
              {carers?.results.map((carer) => (
                <tr
                  key={carer.uid}
                  className={`${
                    selectedUserId === carer.uid ? "bg-yellow-200" : ""
                  } hover:bg-yellow-100 `}
                >
                  <td
                    onClick={() => {
                      setSelectedCarer(carer.uid);
                      setIsDialogOpen(true);
                    }}
                    className="py-2 px-4 border-b border-black cursor-pointer"
                  >{`${carer.user.first_name} ${carer.user.last_name}`}</td>
                  <td className="py-2 px-4 border-b border-black">
                    {carer.phone_number}
                  </td>
                  <td className="py-2 px-4 border-b border-black">
                    {`${carer.location.city} ${carer.location.country.name}`}
                  </td>
                  <td className="py-2 px-4 border-b border-black">
                    <div className="flex items-center space-x-2">
                      {checkAvailability(carer.uid) ? (
                        <>
                          <div className="h-2 w-2 rounded-full bg-green-500 animate-blink"></div>
                          <span>Available</span>
                        </>
                      ) : (
                        <span>Not Available</span>
                      )}
                    </div>
                  </td>
                  <td className="py-2 px-4 border-b border-black">
                    <button
                      onClick={() => handleSelectUser(carer.uid)}
                      className={`py-1 px-2 border border-black rounded ${
                        selectedUserId === carer.uid
                          ? "bg-black text-yellow-400"
                          : "bg-yellow-400 text-black"
                      }`}
                    >
                      {selectedUserId === carer.uid ? "Selected" : "Select"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <AnimatePresence>
        {isDialogOpen && (
          <DialogLayout>
            <CarerDetails
              handleCloseDialog={handleCloseCarerDialog}
              id={selectedCarer}
            />
          </DialogLayout>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isReportOpen && (
          <DialogLayout>
            <ReportDetails
              id={selectedShiftId}
              handleCloseDialog={() => setIsReportOpen(false)}
            />
          </DialogLayout>
        )}
      </AnimatePresence>
      {selectedUserId &&
        assignedAppointments !== undefined &&
        assignedAppointments.length < 1 && (
          <div className="mt-4 p-4">
            <button
              onClick={handleAllocateCarer}
              className="bg-black w-1/4 text-white rounded-lg px-6 py-2"
            >
              {isPending ? <SubmitSpinner /> : "+ Assign Carer"}
            </button>
          </div>
        )}
      {assignedAppointments !== undefined &&
        assignedAppointments.length > 0 && (
          <div className="p-4">
            <h1 className="mt-3 mb-3 font-bold">
              Appointment Details and Reports
            </h1>
            <table className="min-w-full bg-white border border-black">
              <thead className="bg-yellow-400">
                <tr>
                  <th className="py-2 px-4 border-b border-black">
                    Carer name
                  </th>
                  <th className="py-2 px-4 border-b border-black">
                    Check in time
                  </th>
                  <th className="py-2 px-4 border-b border-black">
                    Check out time
                  </th>
                  <th className="py-2 px-4 border-b border-black">
                    Patient name
                  </th>
                  <th className="py-2 px-4 border-b border-black">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredDailyAppointments?.map((appointment) => (
                  <tr key={appointment.uid} className="hover:bg-yellow-100">
                    <td
                      onClick={() => {
                        setSelectedCarer(appointment.allocation.carer.uid);
                        setIsDialogOpen(true);
                      }}
                      className="py-2 px-4 border-b border-black cursor-pointer"
                    >{`${appointment.allocation.carer.user.first_name} ${appointment.allocation.carer.user.last_name}`}</td>
                    <td className="py-2 px-4 border-b border-black">
                      {dayjs(appointment.checked_in_at).format(
                        "MMMM D, YYYY, h:mm A",
                      )}
                    </td>
                    <td className="py-2 px-4 border-b border-black">
                      {dayjs(appointment.checked_out_at).format(
                        "MMMM D, YYYY, h:mm A",
                      )}
                    </td>
                    <td className="py-2 px-4 border-b border-black">
                      {`${appointment.allocation.booking.patient.user.first_name} ${appointment.allocation.booking.patient.user.last_name}`}
                    </td>
                    <td className="py-2 px-4 border-b border-black">
                      <button
                        onClick={() => {
                          setSelectedShiftId(appointment.uid);
                          setIsReportOpen(true);
                        }}
                        className="py-1 px-2 border border-black rounded bg-black text-yellow-400"
                      >
                        View Report
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
    </>
  );
};

export default AppointmentDialog;
