/* eslint-disable */

import React from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import AuthLayout from "../../Layouts/AuthLayout";
import SubmitButton from "../../Components/Common/SubmitButton";
import { useNavigate, useParams } from "react-router-dom";
import useUploadCarerDocuments from "../../Hooks/services/Carers/useUploadCarerDocuments";
import { toast } from "react-toastify";
import DocumentComponentUpload from "../../Components/Forms/DocumentComponentUpload";

interface Document {
  file_name: string;
  file: File | null;
  file_type: string;
  document_type: string;
  original_file_name: string;
  expiry_date: string;
  other_name: string;
}

interface MyFormValues {
  document: Document[]; // Array of Document objects
}
const fileNameOptions = [
  { value: "Nurse practioner", label: "Nurse Practioner" },
  { value: "Doctor license", label: "Doctor License" },
  { value: "Other", label: "Other" },
];
type Props = {
  handleNextStep: () => void;
  handlePrevStep: () => void;
};
const TrainingDocuments = ({ handlePrevStep }: Props) => {
  const navigate = useNavigate();
  const params = useParams();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<MyFormValues>({
    defaultValues: {
      document: [
        {
          file_name: "",
          file: null,
          file_type: "",
          other_name: "",
          document_type: "LICENSE",
          original_file_name: "",
          expiry_date: "",
        },
      ],
    },
  });
  const { mutateAsync, isPending } = useUploadCarerDocuments();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "document",
  });
  const selectedFileNames = watch("document") || [];
  const handleFileChange = (index: number, file: File | null) => {
    setValue(`document.${index}.file`, file);
    if (file) {
      setValue(`document.${index}.file_type`, file.type);
      setValue(`document.${index}.original_file_name`, file.name);
      setValue(`document.${index}.document_type`, "LICENSE");
    } else {
      setValue(`document.${index}.file_type`, "");
    }
  };
  const onSubmit = async (data: MyFormValues) => {
    const post = { ...data, uid: params.id || "" };
    await mutateAsync(post)
      .then(() => navigate("/thanks"))
      .catch(() =>
        toast.error("There was an error adding the details", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      );
  };

  return (
    <AuthLayout
      handlePrevStep={handlePrevStep}
      title="Add Your Training and License"
      other
      label="Provide details about your relevant training and certifications"
    >
      <form
        className="lg:w-3/4 w-full mt-4 mb-4 mx-auto justify-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        {fields.map((field, index) => (
          <div key={field.id}>
            <Controller
              control={control}
              name={`document.${index}.file_name`}
              render={({ field }) => (
                <div className="mb-4">
                  <label
                    htmlFor="file-name"
                    className="block text-black text-sm font-bold mb-2"
                  >
                    Select the name of the document being uploaded
                  </label>
                  <select
                    {...field}
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline ${
                      errors.document?.[index]?.file_name
                        ? "border-red-500"
                        : ""
                    }`}
                  >
                    <option value="" disabled>
                      Select a file name
                    </option>
                    {fileNameOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            />
            {/* You can manage the error messages directly here if needed */}
            {errors.document?.[index]?.file_name && (
              <p className="text-red-500">File name is required</p>
            )}
            {selectedFileNames[index]?.file_name === "Other" && (
              <>
                <Controller
                  control={control}
                  name={`document.${index}.other_name`}
                  render={({ field }) => (
                    <div className="mb-4">
                      <label
                        htmlFor="file-name"
                        className="block text-black text-sm font-bold mb-2"
                      >
                        Enter the name of the document being uploaded
                      </label>
                      <input
                        {...field}
                        placeholder="Enter the document name"
                        className={`shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline ${
                          errors.document?.[index]?.other_name
                            ? "border-red-500"
                            : ""
                        }`}
                      />
                    </div>
                  )}
                />
                {/* You can manage the error messages directly here if needed */}
                {errors.document?.[index]?.other_name && (
                  <p className="text-red-500">File name is required</p>
                )}
              </>
            )}
            <Controller
              control={control}
              name={`document.${index}.expiry_date`}
              render={({ field }) => (
                <div className="mb-4">
                  <label
                    htmlFor="file-name"
                    className="block text-black text-sm font-bold mb-2"
                  >
                    When does the document expire (optional)
                  </label>
                  <input
                    {...field}
                    placeholder="expiry date"
                    type="date"
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline ${
                      errors.document?.[index]?.other_name
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                </div>
              )}
            />
            {/* You can manage the error messages directly here if needed */}
            {errors.document?.[index]?.other_name && (
              <p className="text-red-500">expiry date is required</p>
            )}
            <Controller
              control={control}
              name={`document.${index}.file`}
              render={({ field }) => (
                <DocumentComponentUpload
                  onFileSelect={(file: any) => handleFileChange(index, file)}
                />
              )}
            />
            {errors.document?.[index]?.file && (
              <p className="text-red-500">File is required</p>
            )}
            {fields.length > 1 && (
              <button
                type="button"
                onClick={() => remove(index)}
                className="px-4 mb-8 mt-8 py-2 bg-transparent border border-red-600 text-red-600 rounded-lg w-full  "
              >
                Remove Document
              </button>
            )}
          </div>
        ))}

        <button
          type="button"
          onClick={() =>
            append({
              file_name: "",
              file: null,
              file_type: "",
              other_name: "",
              document_type: "LICENSE",
              original_file_name: "",
              expiry_date: "",
            })
          }
          className="px-4 mt-8 py-2 bg-transparent border border-primary text-primary rounded-lg w-full  "
        >
          + Add training document
        </button>

        <div className="flex flex-col items-center justify-center">
          <SubmitButton label="Submit" isLoading={isPending} />
        </div>
      </form>
    </AuthLayout>
  );
};

export default TrainingDocuments;
