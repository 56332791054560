/* eslint-disable */

import { useEffect, useState } from "react";
import Input from "../../Components/Forms/Input";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  createCarerContactDetailsSchema,
  createPatientsSymptomsSchema,
} from "../../config/validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import Spinner from "../../Components/Common/Spinner";
import useUpdateCarer from "../../Hooks/services/Carers/useUpdateCarer";
import { toast } from "react-toastify";
import SubmitSpinner from "../../Components/Common/SubmitSpinner";
import useGetCarerContactDetails from "../../Hooks/services/Carers/useGetContactDetails";
import useGetPatientSymptomDetails from "../../Hooks/services/Patients/useGetPatientSymptoms";
import { CreatePatientSymptoms } from "../../config/types";
import Select from "../../Components/Forms/Select";
import Textarea from "../../Components/Forms/TextArea";
import { externalBodyParts, painRates } from "../../config/utils";
import useUpdatePatientSymptoms from "../../Hooks/services/Patients/useUpdatePatientSymptoms";
type Props = {
  id: string;
};
const ViewSymptomsDetails = ({ id }: Props) => {
  const params = useParams<{ id: string }>();
  const [initialValues, setInitialValues] =
    useState<CreatePatientSymptoms | null>(null);
  const { data: symptoms, isLoading } = useGetPatientSymptomDetails({
    uid: id,
  });

  const { control, handleSubmit, reset } = useForm<CreatePatientSymptoms>({
    resolver: yupResolver(createPatientsSymptomsSchema),
    defaultValues: initialValues || {},
  });

  const { mutateAsync: updatingMutation, isPending: updatePending } =
    useUpdatePatientSymptoms();

  const symptom = symptoms?.results[0];

  useEffect(() => {
    if (symptom) {
      setInitialValues({
        affected_area: symptom.affected_area || "",
        painscale: symptom.painscale.toString() || "",
        severity_level: symptom.severity_level || "",
        symptom_duration: symptom.symptom_duration.toString() || "",
        description: symptom.description || "",
        duration_type: "",
      });
    }
  }, [symptom]);

  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  }, [initialValues, reset]);

  const onSubmit: SubmitHandler<CreatePatientSymptoms> = async (data: any) => {
    const newData = {
      ...data,
      patient_uid: symptom?.uid || "",
    };
    await updatingMutation(newData)
      .then(() =>
        toast.success("The symptoms were updated successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      )
      .catch(() =>
        toast.error("There was an error updating the symptoms", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      );
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <form className="w-3/4 mt-4 mb-4  " onSubmit={handleSubmit(onSubmit)}>
      <div className="flex w-full justify-between">
        <div className="flex space-x-4 justify-between">
          <Select
            name="affected_area"
            label="What part of the body is affected?"
            control={control}
            options={externalBodyParts}
            placeholder="select a body part"
          />
          <Input<CreatePatientSymptoms>
            name="symptom_duration"
            label="Symptom Duration"
            control={control}
            placeholder="Enter the duration"
          />
        </div>
        <div className="flex space-x-4 justify-between">
          <Select
            name="duration_type"
            label="Days/weeks/months/years"
            control={control}
            options={[
              { value: "DAYS", label: "Days" },
              { value: "WEEKS", label: "Weeks" },
              { value: "MONTHS", label: "Months" },
              { value: "YEARS", label: "Years" },
            ]}
            placeholder="select duration type"
          />
          <Select
            name="severity_level"
            label="Severity of the symptoms"
            control={control}
            options={[
              { value: "MILD", label: "Mild" },
              { value: "MODERATE", label: "Moderate" },
              { value: "SEVERE", label: "Severe" },
            ]}
            placeholder="select duration type"
          />
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex space-x-4 justify-between">
          <Textarea
            name="description"
            label="Symptoms Description"
            control={control}
            placeholder="Enter a description"
          />

          <Select
            name="painscale"
            label="What's the pain level on a scale of 1-10"
            control={control}
            options={painRates}
            placeholder="select a nationality"
          />
        </div>
      </div>
      <div className="flex  w-full justify-end">
        <div className="w-1/4 flex space-x-4 justify-end">
          <button
            className={`${updatePending ? "bg-black text-white" : " bg-primary text-white"} px-6 w-full py-2  rounded-lg  font-semibold`}
          >
            {updatePending ? <SubmitSpinner /> : "Update"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ViewSymptomsDetails;
