/* eslint-disable */
import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import axiosInstance from "../../common/AxiosInstance";
import { CreateAdminUserParams } from "../../../config/types";

const createAdminUsers = async (data: CreateAdminUserParams) => {
  const newData = {
    ...data,
    admin: "34ac5386-cd8b-49b3-b251-5d4dee843aef",
    location: "ee384b32-4758-4443-b207-a8fc35eb4ea9",
  };
  const response = await axiosInstance.post(
    `/portal/portal-users/users/`,
    newData,
  );

  return response.data; // Assuming you need just the data part of the response
};

const useCreateAdminUsers = (): UseMutationResult<
  any,
  Error, // Use `Error` for mutation error type
  CreateAdminUserParams
> => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createAdminUsers,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: ["users"] }) // Ensure correct queryKey usage
        .then(() => {
          navigate("/users");
        })
        .catch((error: Error) => {
          // Handle errors properly
          console.error("Error invalidating queries:", error);
          throw new Error(error.message);
        });
    },
  });
};

export default useCreateAdminUsers;
