/* eslint-disable react/react-in-jsx-scope */
import {
  MultiSelect as ReactMultiSelect,
  Option,
} from "react-multi-select-component";
import { useController, Control, FieldValues, Path } from "react-hook-form";

interface MultiSelectProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  options: Option[];
  disabled?: boolean;
}

const MultiSelect = <T extends FieldValues>({
  name,
  label,
  control,
  options,
  disabled = false,
}: MultiSelectProps<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: undefined,
  });

  // Ensure field.value is always an array
  const fieldValue = Array.isArray(field.value) ? field.value : [];

  return (
    <div className="mb-4">
      <label className="block text-black text-sm font-medium mb-2">
        {label}
      </label>
      <ReactMultiSelect
        options={options}
        value={fieldValue}
        onChange={(selectedOptions: any) => field.onChange(selectedOptions)}
        labelledBy={label}
        disabled={disabled}
        className={`${error ? "border-red-500" : ""} shadow appearance-none border border-borderGray rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline`}
      />
      {error && <p className="text-red-500 text-xs mt-2">{error.message}</p>}
    </div>
  );
};

export default MultiSelect;
