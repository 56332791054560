/* eslint-disable */

import { useEffect, useState } from "react";
import BreadCrumbLayout from "../../Layouts/BreadCrumbLayout";
import HeaderLayout from "../../Layouts/HeaderLayout";
import CollapsibleContainer from "../../Layouts/CollapsibleLayout";
import Input from "../../Components/Forms/Input";
import { useForm, SubmitHandler } from "react-hook-form";
import { UpdatePatientParams } from "../../config/types";
import { updatePatientSchema } from "../../config/validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import DOBInput from "../../Components/Forms/DateOfBirthInput";
import { useParams } from "react-router-dom";
import useGetCarers from "../../Hooks/services/Carers/useGetCarers";
import Spinner from "../../Components/Common/Spinner";
import useUpdateCarer from "../../Hooks/services/Carers/useUpdateCarer";
import { toast } from "react-toastify";
import SubmitSpinner from "../../Components/Common/SubmitSpinner";
import useDeleteCarer from "../../Hooks/services/Carers/useDeleteCarer";

import useReverseGeocoding from "../../Hooks/common/useReverseGeocoding";
import ViewSingleContactDetails from "../Carers/ViewContactDetails";
import ViewWorkExperienceInformation from "../Carers/ViewWorkExperienceInformation";
import ViewLicenseDocuments from "../Carers/ViewLicenseDocuments";
import ViewIdentificationDocuments from "../Carers/ViewIdentificationDocuments";
type Props = {
  id: string;
  handleCloseDialog: () => void;
};
const CarerDetails = ({ id, handleCloseDialog }: Props) => {
  const [initialValues, setInitialValues] =
    useState<UpdatePatientParams | null>(null);

  const { control, handleSubmit, reset } = useForm<UpdatePatientParams>({
    resolver: yupResolver(updatePatientSchema),
    defaultValues: initialValues || {},
  });

  const { data: carers, isLoading } = useGetCarers({
    uid: id,
  });

  const carer = carers?.results[0];

  const { placeName, loading, error } = useReverseGeocoding(
    parseFloat(carer?.location.latitude || "0"),
    parseFloat(carer?.location.longitude || "0"),
  );
  const { mutateAsync: updatingMutation, isPending: updatePending } =
    useUpdateCarer();
  const { mutateAsync: deletingMutation, isPending: deletePending } =
    useDeleteCarer();
  const [isOpen, setIsOpen] = useState(true);

  const toggleCollapse = () => {
    setIsOpen((prev) => !prev);
  };
  useEffect(() => {
    if (carer) {
      setInitialValues({
        first_name: carer.user.first_name || "",
        last_name: carer.user.last_name || "",
        email: carer.user.email || "",
        phone_number: carer.phone_number || "",
        date_of_birth: carer.date_of_birth || "",
        gender: "",
      });
    }
  }, [carer]);

  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  }, [initialValues, reset]);
  const handleDelete = async () => {
    await deletingMutation(id)
      .then(() =>
        toast.success("The carer was deleted successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      )
      .catch(() =>
        toast.error("There was an error deleting the carer", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      );
  };

  const onSubmit: SubmitHandler<UpdatePatientParams> = async (data: any) => {
    const newData = {
      ...data,
      admin: "34ac5386-cd8b-49b3-b251-5d4dee843aef",
      location: "ee384b32-4758-4443-b207-a8fc35eb4ea9",
      uid: id,
    };
    await updatingMutation(newData)
      .then(() =>
        toast.success("The user was updated successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      )
      .catch(() =>
        toast.error("There was an error updating the user", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      );
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="bg-lightGray p-4 border-b">
      <div className="flex justify-between mb-4  items-center ">
        <h3 className="text-lg font-medium">Carer Details</h3>
        <button
          onClick={handleCloseDialog}
          className="text-xl bg-darkGray rounded-full h-8 w-8 text-center shadow-xl font-bold"
        >
          &times;
        </button>
      </div>
      <div className="w-full">
        <div className="mb-4">
          <div className="border border-gray-300 rounded-lg shadow-md">
            <button
              onClick={toggleCollapse}
              className="w-full px-4 font-bold text-black py-4 text-left bg-lightGray border-b border-gray-300 rounded-t-lg focus:outline-none"
            >
              Personal Details
              <span
                className={`float-right transform transition-transform ${isOpen ? "rotate-180" : ""}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
            </button>
            {isOpen && (
              <div className="p-4 bg-white">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="w-full mt-4 mb-4"
                >
                  <div className="flex w-full justify-between">
                    <div className="flex space-x-4 justify-between">
                      <Input<UpdatePatientParams>
                        name="first_name"
                        label="First Name"
                        control={control}
                        placeholder="Enter your first name"
                      />
                      <Input<UpdatePatientParams>
                        name="last_name"
                        label="Last Name"
                        control={control}
                        placeholder="Enter your last name"
                      />
                    </div>
                    <div className="flex space-x-4 justify-between">
                      <Input<UpdatePatientParams>
                        name="email"
                        label="Email"
                        control={control}
                        placeholder="Enter your email"
                        type="email"
                      />
                      <Input<UpdatePatientParams>
                        name="phone_number"
                        label="Phone Number"
                        control={control}
                        placeholder="Enter your phone number"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="flex space-x-4 justify-between">
                      <DOBInput<UpdatePatientParams>
                        name="date_of_birth"
                        label="Date of Birth"
                        control={control}
                        placeholder="YYYY-MM-DD"
                      />
                    </div>
                  </div>
                  <div className="flex  w-full justify-end">
                    <div className="w-1/4 flex space-x-4 justify-end">
                      <button
                        className={`${updatePending ? "bg-black text-white" : " bg-primary text-white"} px-6 w-full py-2  rounded-lg  font-semibold`}
                      >
                        {updatePending ? <SubmitSpinner /> : "Update"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>

        <div className="mb-4">
          <CollapsibleContainer title="Contact Information">
            <ViewSingleContactDetails id={id} />
          </CollapsibleContainer>
        </div>
        <div className="mb-4">
          <CollapsibleContainer title="Bank Information">
            <form onSubmit={handleSubmit(onSubmit)} className="w-3/4 mt-4 mb-4">
              <div className="flex w-full justify-between">
                <div className="flex space-x-4 justify-between">
                  <Input<UpdatePatientParams>
                    name="first_name"
                    label="First Name"
                    control={control}
                    placeholder="Enter your first name"
                  />
                  <Input<UpdatePatientParams>
                    name="last_name"
                    label="Last Name"
                    control={control}
                    placeholder="Enter your last name"
                  />
                </div>
                <div className="flex space-x-4 justify-between">
                  <Input<UpdatePatientParams>
                    name="email"
                    label="Email"
                    control={control}
                    placeholder="Enter your email"
                    type="email"
                  />
                  <Input<UpdatePatientParams>
                    name="phone_number"
                    label="Phone Number"
                    control={control}
                    placeholder="Enter your phone number"
                    type="text"
                  />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex space-x-4 justify-between">
                  <DOBInput<UpdatePatientParams>
                    name="date_of_birth"
                    label="Date of Birth"
                    control={control}
                    placeholder="YYYY-MM-DD"
                  />
                </div>
              </div>
              <div className="flex  w-full justify-end">
                <div className="w-1/4 flex space-x-4 justify-end">
                  <button
                    className={`${updatePending ? "bg-black text-white" : " bg-primary text-white"} px-6 w-full py-2  rounded-lg  font-semibold`}
                  >
                    {updatePending ? <SubmitSpinner /> : "Update"}
                  </button>
                  <button
                    type="button"
                    onClick={handleDelete}
                    className={`${updatePending ? "bg-primary text-white" : "bg-black text-white "} px-6 w-full py-2  rounded-lg  font-semibold`}
                  >
                    {deletePending ? <SubmitSpinner /> : "Delete"}
                  </button>
                </div>
              </div>
            </form>
          </CollapsibleContainer>
        </div>
        <div className="mb-4">
          <CollapsibleContainer title="Work Experience Information">
            <ViewWorkExperienceInformation id={id} />
          </CollapsibleContainer>
        </div>
        <div className="mb-4">
          <CollapsibleContainer title="Identification Documents">
            <ViewIdentificationDocuments id={id} />
          </CollapsibleContainer>
        </div>
        <div className="mb-4">
          <CollapsibleContainer title="Trainings and Licenses Documents">
            <ViewLicenseDocuments id={id} />
          </CollapsibleContainer>
        </div>
      </div>
    </div>
  );
};

export default CarerDetails;
