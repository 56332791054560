import React, { useRef } from "react";
import { Search } from "lucide-react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

interface Job {
  company: string;
  location: string;
}

const jobs: Job[] = [
  { company: "CarePlus Services", location: "London, UK" },
  { company: "CarePlus Services", location: "London, UK" },
  { company: "CarePlus Services", location: "London, UK" },
  { company: "CarePlus Services", location: "London, UK" },
  { company: "CarePlus Services", location: "London, UK" },
];

const JobListing: React.FC = () => {
  const navigate = useNavigate();
  const aboutRef = useRef<HTMLDivElement | null>(null);
  const contactRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = (section: "home" | "about" | "contact") => {
    if (section === "about" && aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "contact" && contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Navbar handleScroll={handleScroll} />
      <div className="container mx-auto px-4 py-8">
        {/* <div className="relative mb-8">
          <input
            type="text"
            placeholder="Search"
            className="w-full p-2 pl-10 border border-gray-300 rounded-md"
          />
          <Search
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            size={20}
          />
        </div> */}

        <h1 className="text-4xl font-semibold mb-4 text-center">Jobs</h1>
        <p className="text-center font-normal text-2xl mb-8">
          Find Your Perfect Job with Doctor Little
        </p>
        <p className="text-sm text-gray-600 mb-12 text-center">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam,
          <br /> quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
          velit esse cillum <br /> dolore eu fugiat nulla pariatur. Excepteur
          sint occaecat cupidatat non proident, sunt in culpa qui officia
          deserunt mollit anim id est laborum.
        </p>

        <div className="space-y-4">
          {jobs.map((job, index) => (
            <div
              key={index}
              className="bg-white py-6 border-2 p-4 lg:w-6/12 mx-auto rounded-2xl shadow-lg flex justify-between items-center"
            >
              <div>
                <h2 className="font-bold text-lg text-black">{job.company}</h2>
                <p className="text-sm text-black font-normal">{job.location}</p>
              </div>
              <button
                onClick={() => navigate("/jobs/posting")}
                className="px-4 py-2 border-2 border-primary text-sm font-normal text-black rounded-md hover:bg-primary hover:text-white transition-colors"
              >
                View Job
              </button>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default JobListing;
