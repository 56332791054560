/* eslint-disable */
import React from "react";
import Checked from "../../assets/big-check.svg";
const ThankYou: React.FC = () => {
  return (
    <>
      <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
        {/* Green checkmark icon */}
        <img src={Checked} className="h-fit w-fit" alt="check" />

        {/* Thank You Message */}
        <h1 className="text-5xl font-bold text-black mb-4">
          Thank You for Your Submission!
        </h1>
        <p className="text-2xl text-black font-medium mb-6">
          Your Additional Information Has Been Successfully Submitted
        </p>

        {/* Instructional Text */}
        <p className="text-center text-black">
          We appreciate you taking the time to provide us with your additional
          information.
          <br />
          Our team will review the information and verify the details as part of
          your application process.
        </p>
      </div>
    </>
  );
};

export default ThankYou;
