/* eslint-disable react/react-in-jsx-scope */
import { useController, Control, FieldValues, Path } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import "./styles.css";

interface DOBInputProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  placeholder?: string;
}

const DOBInput = <T extends FieldValues>({
  name,
  label,
  control,
  placeholder = "Select date",
}: DOBInputProps<T>) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: undefined, // Change from null to undefined
  });

  const handleDateChange = (date: Date | null) => {
    onChange(date ? format(date, "yyyy-MM-dd") : undefined); // Change null to undefined
  };

  return (
    <div className="mb-4 customDatePickerWidth">
      <label
        htmlFor={name}
        className="block text-black text-sm font-medium mb-2"
      >
        {label}
      </label>
      <DatePicker
        selected={value ? new Date(value) : null}
        onChange={handleDateChange}
        onBlur={onBlur}
        placeholderText={placeholder}
        ref={ref} // Ensure ref is correctly passed
        className={`shadow appearance-none border border-borderGray rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline ${
          error ? "border-red-500" : ""
        }`}
        dateFormat="yyyy-MM-dd"
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
      />
      {error && (
        <p className="text-red-500 text-xs italic mt-2">{error.message}</p>
      )}
      {/* Hidden input to ensure proper focusing */}
      <input type="text" ref={ref} className="hidden" />
    </div>
  );
};

export default DOBInput;
