import React from "react";
import HeroImage from "../../assets/hero-image.png";
import { useNavigate } from "react-router-dom";
const HeroSection: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="relative h-screen">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center z-0"
        style={{
          backgroundImage: `url(${HeroImage})`,
          filter: "brightness(60%)",
        }}
      />

      {/* Content Overlay */}
      <div className="relative z-10 flex items-center justify-center h-full px-4 sm:px-6 lg:px-8">
        <div className="text-center max-w-3xl">
          <h1 className="text-6xl sm:text-5xl md:text-6xl font-semibold text-white mb-4">
            Your trusted partner in
            <span className="block">
              innovative <span className="text-green-400">healthcare</span>{" "}
              solutions.
            </span>
          </h1>
          <p className="text-lg sm:text-xl text-gray-200 mb-8">
            Curabitur auctor, odio eu consectetur cursus, libero dui sodales
            erat, risus id mi. Pellentesque amet sit in urna vitae magna blandit
            consequat. Nulla facilisi. Duis sit amet nulla vehicula lacinia ac
            ac erat. Donec lacinia felis et urna consectetur maximus enim
            vestibulum.
          </p>
          <button
            onClick={() => navigate("/jobs")}
            className="bg-primary text-white font-medium py-4 px-10 rounded-full text-lg hover:bg-doctorLittleGreen transition duration-300"
          >
            Search Jobs
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
