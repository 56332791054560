/* eslint-disable */

import { useEffect, useState } from "react";
import Input from "../../Components/Forms/Input";
import { useForm, SubmitHandler } from "react-hook-form";
import { createCarerContactDetailsSchema } from "../../config/validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import Spinner from "../../Components/Common/Spinner";
import useUpdateCarer from "../../Hooks/services/Carers/useUpdateCarer";
import { toast } from "react-toastify";
import SubmitSpinner from "../../Components/Common/SubmitSpinner";
import useGetCarerContactDetails from "../../Hooks/services/Carers/useGetContactDetails";
import { CreateCarerContactDetails } from "../../config/types";
type Props = {
  id: string;
};
const ViewSingleContactDetails = ({ id }: Props) => {
  const params = useParams<{ id: string }>();
  const [initialValues, setInitialValues] =
    useState<CreateCarerContactDetails | null>(null);

  const { control, handleSubmit, reset } = useForm<CreateCarerContactDetails>({
    resolver: yupResolver(createCarerContactDetailsSchema),
    defaultValues: initialValues || {},
  });

  const { data: contacts, isLoading } = useGetCarerContactDetails({ uid: id });
  const { mutateAsync: updatingMutation, isPending: updatePending } =
    useUpdateCarer();

  const contact = contacts?.results[0];

  useEffect(() => {
    if (contact) {
      setInitialValues({
        postal_code: contact.postal_code || "",
        next_of_kin_email: contact.next_of_kin_email || "",
        next_of_kin_name: contact.next_of_kin_name || "",
        next_of_kin_phone_number: contact.next_of_kin_phone_number || "",
        city: "",
        next_of_kin_relationship: "",
      });
    }
  }, [contact]);

  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  }, [initialValues, reset]);

  const onSubmit: SubmitHandler<CreateCarerContactDetails> = async (
    data: any,
  ) => {
    const newData = {
      ...data,
      admin: "34ac5386-cd8b-49b3-b251-5d4dee843aef",
      location: "ee384b32-4758-4443-b207-a8fc35eb4ea9",
      uid: params.id || "",
    };
    await updatingMutation(newData)
      .then(() =>
        toast.success("The user was updated successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      )
      .catch(() =>
        toast.error("There was an error updating the user", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      );
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full mt-4 mb-4">
      <div className="flex w-full justify-between">
        <div className="flex space-x-4 justify-between">
          <Input<CreateCarerContactDetails>
            name="postal_code"
            label="Postal code"
            control={control}
            placeholder="Enter your postal code"
          />
          <Input<CreateCarerContactDetails>
            name="city"
            label="City"
            control={control}
            placeholder="Enter your city"
          />
        </div>
        <div className="flex space-x-4 justify-between">
          <Input<CreateCarerContactDetails>
            name="next_of_kin_email"
            label="Next of kin email"
            control={control}
            placeholder="Enter your email"
            type="email"
          />
          <Input<CreateCarerContactDetails>
            name="next_of_kin_phone_number"
            label="Next of kin phone number"
            control={control}
            placeholder="Enter phone number"
            type="text"
          />
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex space-x-4 justify-between">
          <Input<CreateCarerContactDetails>
            name="next_of_kin_name"
            label="Next of kin name"
            control={control}
            placeholder="Enter name"
            type="text"
          />
          <Input<CreateCarerContactDetails>
            name="next_of_kin_relationship"
            label="Next of kin relationship"
            control={control}
            placeholder="Enter your relationship"
            type="text"
          />
        </div>
      </div>
      <div className="flex  w-full justify-end">
        <div className="w-1/4 flex space-x-4 justify-end">
          <button
            className={`${updatePending ? "bg-black text-white" : " bg-primary text-white"} px-6 w-full py-2  rounded-lg  font-semibold`}
          >
            {updatePending ? <SubmitSpinner /> : "Update"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ViewSingleContactDetails;
