/* eslint-disable */

import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";

import axiosInstance from "../../common/AxiosInstance";
import { CreateAdminUserParams } from "../../../config/types";

const updateAdminUser = async (data: CreateAdminUserParams) => {
  const response = await axiosInstance.patch(
    `/portal/portal-users/users/${data.uid}/`,
    data,
  );

  return response;
};

const useUpdateAdminUsers = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Response,
  CreateAdminUserParams
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateAdminUser,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: ["users"] })
        .catch((error: Error) => {
          throw new Error(error.message);
        });
    },
  });
};

export default useUpdateAdminUsers;
