import React, { useRef } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

const JobPosting = () => {
  const aboutRef = useRef<HTMLDivElement | null>(null);
  const contactRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const handleScroll = (section: "home" | "about" | "contact") => {
    if (section === "about" && aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "contact" && contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Navbar handleScroll={handleScroll} />
      <div className="lg:w-10/12 w-11/12 mx-auto py-16">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-4xl font-medium">CarePlus Services</h1>
          <button
            onClick={() => navigate("/carer/create-carer")}
            className="bg-primary hidden md:flex text-white px-4 py-2 rounded-md hover:bg-primary transition"
          >
            Apply Now
          </button>
        </div>

        <p className="text-black text-sm font-normal mb-4">London, UK</p>
        <p className="text-black text-sm font-normal mb-4">
          Job Type: Full-Time / Part-Time
        </p>

        <h2 className="text-sm font-semibold mb-2">Overview:</h2>
        <p className="mb-4 text-sm font-normal text-black">
          At CarePlus Services Ltd., we are dedicated to providing exceptional
          care to our clients in their homes.
          <br /> We are currently seeking compassionate and reliable Carers to
          join our team.
          <br /> If you have a passion for making a positive impact on people's
          lives and are committed to delivering high-quality care,
          <br /> we want to hear from you!
        </p>

        <h2 className="text-sm font-semibold mb-2">Key Responsibilities:</h2>
        <ul className="list-disc list-inside mb-4 text-sm font-normal text-black p-4">
          <li>
            Provide personal care and assistance to clients, including help with
            bathing, dressing, and grooming.
          </li>
          <li>
            Assist with mobility and transfers, ensuring client safety and
            comfort.
          </li>
          <li>
            Administer medications as prescribed and monitor client health.
          </li>
          <li>
            Prepare and serve meals according to dietary needs and preferences.
          </li>
          <li>Offer companionship and emotional support to clients.</li>
          <li>
            Maintain accurate records of care provided and report any changes in
            client conditions.
          </li>
        </ul>

        <h2 className="text-sm font-semibold mb-2">Requirements:</h2>
        <ul className="list-disc list-inside mb-4 text-sm font-normal text-black p-4">
          <li>
            Previous experience in a care role is preferred but not essential.
          </li>
          <li>NVQ Level 2 or 3 in Health and Social Care is an advantage.</li>
          <li>Strong communication and interpersonal skills.</li>
          <li>Ability to work independently and as part of a team.</li>
          <li>Valid UK driving license and access to a car is preferred.</li>
          <li>A compassionate and patient approach to care.</li>
        </ul>

        <button
          onClick={() => navigate("/carer/create-carer")}
          className="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary transition lg:w-1/12"
        >
          Apply Now
        </button>
      </div>
      <Footer />
    </>
  );
};

export default JobPosting;
