/* eslint-disable */

import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import useCreateCarer from "../../Hooks/services/Carers/useCreateCarer";
import Select from "../../Components/Forms/Select";
import SubmitButton from "../../Components/Common/SubmitButton";
import AuthLayout from "../../Layouts/AuthLayout";
import { CreateCarerWorkDetails } from "../../config/types";
import { createCarerWorkerDetailsSchema } from "../../config/validationSchema";
import { Option } from "react-multi-select-component"; // Importing the MultiSelect component
import MultiSelect from "../../Components/Forms/MultiSelect";
import { languageOptions, nationalityOptions } from "../../config/utils";
import RadioGroup from "../../Components/Forms/RadioInput";
import Input from "../../Components/Forms/Input";
import useUpdateCarer from "../../Hooks/services/Carers/useUpdateCarer";
import { toast } from "react-toastify";

type Props = {
  handleNextStep: () => void;
  handlePrevStep: () => void;
};
const WorkDetails = ({ handleNextStep, handlePrevStep }: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<CreateCarerWorkDetails>({
    resolver: yupResolver(createCarerWorkerDetailsSchema),
  });

  const { mutateAsync, isPending } = useUpdateCarer();

  const onSubmit: SubmitHandler<CreateCarerWorkDetails> = async (
    data: CreateCarerWorkDetails, // Use the correct type
    e: React.BaseSyntheticEvent | undefined // Ensure correct event type
  ) => {
    const post = {
      national_insurance: data.national_insurance,
      marital_status: data.marital_status,
      nationality: data.nationality,
      languages: data.languages.map((option) => {
        return option.label;
      }),
      is_student: data.is_student === "yes" ? true : false,
      uid: params.id,
    };
    await mutateAsync(post)
      .then(() => handleNextStep())
      .catch(() =>
        toast.error("There was an error adding the details", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      );
  };
  // Define the options for the multi-select component

  return (
    <AuthLayout
      title="Add Your Work Details"
      other
      label="Provide details about your work details"
      handlePrevStep={handlePrevStep}
    >
      <form
        className="md:w-3/4 w-full mt-4 mb-4 mx-auto justify-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input<CreateCarerWorkDetails>
          name="national_insurance"
          label="National Insurance Number"
          control={control}
          placeholder="Enter your NI number"
        />
        <Select
          name="marital_status"
          label="Select a status"
          control={control}
          options={[
            { value: "MARRIED", label: "Married" },
            { value: "SINGLE", label: "Single" },
            { value: "DIVORCED", label: "Divorced" },
          ]}
          placeholder="select a patient"
        />
        <Select
          name="nationality"
          label="Select a nationality"
          control={control}
          options={nationalityOptions}
          placeholder="select a nationality"
        />
        <MultiSelect
          name="languages"
          label="Select Languages"
          control={control}
          options={languageOptions}
        />
        <RadioGroup
          name="is_student"
          control={control}
          label="Are you a medical student?"
          options={[
            { value: "YES", label: "Yes" },
            { value: "NO", label: "No" },
          ]}
        />
        <div className="flex flex-col items-center justify-center">
          <SubmitButton label="Submit" isLoading={isPending} />
        </div>
      </form>
    </AuthLayout>
  );
};

export default WorkDetails;
