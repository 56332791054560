/* eslint-disable */

import BreadCrumbLayout from "../../Layouts/BreadCrumbLayout";
import HeaderLayout from "../../Layouts/HeaderLayout";
import Input from "../../Components/Forms/Input";
import DOBInput from "../../Components/Forms/DateOfBirthInput";
import SubmitButton from "../../Components/Common/SubmitButton";
import { CreateAppointmentParams } from "../../config/types";
import { createAppointmentSchema } from "../../config/validationSchema";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "../../Components/Forms/Select";
import Spinner from "../../Components/Common/Spinner";
import useGetPatients from "../../Hooks/services/Patients/useGetPatients";
import useCreateAppointment from "../../Hooks/services/Appointments/useCreateAppointment";
import LocationInput from "../../Components/Forms/LocationInput";

const CreateAppointment = () => {
  const { control, handleSubmit } = useForm<CreateAppointmentParams>({
    resolver: yupResolver(createAppointmentSchema),
  });
  const { data: patients, isLoading } = useGetPatients({});
  const { mutateAsync, isPending } = useCreateAppointment();
  const transformedPatient = patients?.results.map((patient) => ({
    value: patient.uid,
    label: `${patient.user?.first_name} ${patient.user?.last_name}`,
  }));
  const onSubmit: SubmitHandler<CreateAppointmentParams> = async (
    data: any,
  ) => {
    await mutateAsync(data)
      .then(() =>
        toast.success("The appointment was created successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      )
      .catch(() =>
        toast.error("There was an error creating the appointment", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      );
  };
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="p-4">
      <BreadCrumbLayout title="Appointment Creation">
        <HeaderLayout title="Appointment Creation">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-5/12 mt-4 mb-4 p-4  mx-auto"
          >
            <Select
              name="patient"
              label="Select a patient"
              control={control}
              options={transformedPatient || []}
              placeholder="select a patient"
            />
            <DOBInput<CreateAppointmentParams>
              name="start_date"
              label="Start Date"
              control={control}
              placeholder="YYYY-MM-DD"
            />
            <DOBInput<CreateAppointmentParams>
              name="end_date"
              label="End Date"
              control={control}
              placeholder="YYYY-MM-DD"
            />
            <Input<CreateAppointmentParams>
              name="start_time"
              label="Start Time"
              control={control}
              placeholder="Enter a time"
              type="time"
            />
            <Input<CreateAppointmentParams>
              name="end_time"
              label="End Time"
              control={control}
              placeholder="Enter a time"
              type="time"
            />
            <Input<CreateAppointmentParams>
              name="rate"
              label="Appointment Rate"
              control={control}
              placeholder="Enter a rate"
              type="number"
            />
            <LocationInput<CreateAppointmentParams>
              name="location"
              label="Location"
              control={control}
              placeholder="Enter a location"
            />
            <div className="flex flex-col items-center justify-center">
              <SubmitButton label="Submit" isLoading={isPending} />
            </div>
          </form>
        </HeaderLayout>
      </BreadCrumbLayout>
    </div>
  );
};

export default CreateAppointment;
