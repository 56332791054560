// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shadow-image {
  filter: drop-shadow(15px 15px #01a659);
}
.image-shadow {
  filter: drop-shadow(-15px -15px #01a659);
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Landing/styles.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;AACxC;AACA;EACE,wCAAwC;AAC1C","sourcesContent":[".shadow-image {\n  filter: drop-shadow(15px 15px #01a659);\n}\n.image-shadow {\n  filter: drop-shadow(-15px -15px #01a659);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
