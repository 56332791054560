/* eslint-disable */
import React from "react";
import Checked from "../../assets/big-check.svg";
const ThankYouForRegistering: React.FC = () => {
  return (
    <>
      <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
        {/* Green checkmark icon */}
        <img src={Checked} className="h-fit w-fit" alt="check" />

        {/* Thank You Message */}
        <h1 className="text-5xl font-bold text-black mb-4">
          Thank You for Registering!
        </h1>
        <p className="text-2xl text-black font-medium mb-6">
          Your registration has been successfully submitted.
        </p>

        {/* Instructional Text */}
        <p className="text-center text-black">
          We appreciate your interest in joining DoctorLittle.
          <br />
          Check your email for further details about the next steps in the
          registration process.
        </p>
      </div>
    </>
  );
};

export default ThankYouForRegistering;
