/* eslint-disable */
import SubmitSpinner from "./SubmitSpinner";
type Props = {
  label: string;
  isLoading: boolean;
};
const SubmitButton = ({ label, isLoading }: Props) => {
  return (
    <button
      type="submit"
      className={`${isLoading ? "bg-black text-white " : "bg-primary hover:bg-black px-12  text-white "} mt-8 w-full  py-2  rounded-lg focus:outline-none focus:shadow-outline`}
    >
      {isLoading ? <SubmitSpinner /> : label}
    </button>
  );
};

export default SubmitButton;
