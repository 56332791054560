/* eslint-disable */

import { AxiosResponse } from "axios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { DailyAppointmentAllocationResponse } from "../../../config/types";
import axiosInstance from "../../common/AxiosInstance";

const fetchData = async (): Promise<DailyAppointmentAllocationResponse> => {
  try {
    const response: AxiosResponse<DailyAppointmentAllocationResponse> =
      await axiosInstance.get("booking/daily/shifts/?limit=100");
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};

const useGetDailyAppointments = (): UseQueryResult<
  DailyAppointmentAllocationResponse,
  Error
> => {
  return useQuery<DailyAppointmentAllocationResponse, Error>({
    queryKey: ["dailyappointments"],
    queryFn: fetchData,
  });
};

export default useGetDailyAppointments;
