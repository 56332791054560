import React from "react";

const FeatureButton: React.FC<{ text: string }> = ({ text }) => (
  <button className="px-6 py-2 rounded-full border-2 border-yellow-400 text-yellow-400 hover:bg-yellow-400 hover:text-green-600 transition duration-300">
    {text}
  </button>
);

const FeatureSection: React.FC = () => {
  return (
    <div className="w-full bg-green-600 py-28 px-4">
      <div className="max-w-6xl mx-auto flex flex-wrap justify-center gap-4">
        <FeatureButton text="Comprehensive Care" />
        <FeatureButton text="Innovative Solutions" />
        <FeatureButton text="Expert Team" />
      </div>
    </div>
  );
};

export default FeatureSection;
