/* eslint-disable */
import { AxiosResponse } from "axios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import axiosInstance from "../../common/AxiosInstance";
import { CarerDocumentsResponse } from "../../../config/types";

interface CarerOptions {
  uid?: string;
}

const fetchData = async (
  options: CarerOptions,
): Promise<CarerDocumentsResponse> => {
  const { uid } = options;

  const queryParams = new URLSearchParams({
    limit: "10",
  });
  if (uid !== undefined && uid !== "") {
    queryParams.append("carer__uid", uid);
  }
  try {
    const response: AxiosResponse<CarerDocumentsResponse> =
      await axiosInstance.get(`/carer/documents/carer/?${queryParams}`);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};

const useGetCarerDocuments = (
  options: CarerOptions,
): UseQueryResult<CarerDocumentsResponse, Error> => {
  return useQuery<CarerDocumentsResponse, Error>({
    queryKey: ["carerdocuments", options.uid],
    queryFn: () => fetchData(options),
  });
};

export default useGetCarerDocuments;
