/* eslint-disable */

import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import useCreateCarer from "../../Hooks/services/Carers/useCreateCarer";
import Select from "../../Components/Forms/Select";
import SubmitButton from "../../Components/Common/SubmitButton";
import AuthLayout from "../../Layouts/AuthLayout";
import { Option } from "react-multi-select-component"; // Importing the MultiSelect component
import MultiSelect from "../../Components/Forms/MultiSelect";
import {
  externalBodyParts,
  languageOptions,
  nationalityOptions,
  painRates,
} from "../../config/utils";
import RadioGroup from "../../Components/Forms/RadioInput";
import Input from "../../Components/Forms/Input";
import useUpdateCarer from "../../Hooks/services/Carers/useUpdateCarer";
import { toast } from "react-toastify";
import Textarea from "../../Components/Forms/TextArea";
import { CreatePatientMedicalHistory } from "../../config/types";
import { createPatientsMedicalHistorySchema } from "../../config/validationSchema";
import useAddPatientMedicalHistory from "../../Hooks/services/Patients/useAddPatientMedicalHistory";
import MultiItemInput from "../../Components/Forms/MultiInput";

// Define your MedicalHistory component
const MedicalHistory = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<CreatePatientMedicalHistory>({
    resolver: yupResolver(createPatientsMedicalHistorySchema),
  });

  const { mutateAsync, isPending } = useAddPatientMedicalHistory();

  const onSubmit: SubmitHandler<CreatePatientMedicalHistory> = async (
    data: CreatePatientMedicalHistory, // Use the correct type
    e: React.BaseSyntheticEvent | undefined, // Ensure correct event type
  ) => {
    const post = {
      ...data,
      patient_uid: params.id,
    };
    await mutateAsync(post)
      .then(() => navigate("/thanks"))
      .catch(() =>
        toast.error("Failed to add the medical history", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        }),
      );
  };
  // Define the options for the multi-select component

  return (
    <AuthLayout other label="Medical History">
      <form
        className="w-3/4 mt-4 mb-4 mx-auto justify-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        <MultiItemInput
          name="existing_conditions"
          label="Existing Conditions"
          control={control}
          placeholder="Add existing conditions and press Enter"
        />

        <MultiItemInput
          name="current_medications"
          label="Current Medications"
          control={control}
          placeholder="Add current medications and press Enter"
        />

        <MultiItemInput
          name="allergies"
          label="Allergies"
          control={control}
          placeholder="Add allergies and press Enter"
        />

        <MultiItemInput
          name="family_history"
          label="Family History"
          control={control}
          placeholder="Add family history and press Enter"
        />

        {/* <MultiSelect
          name="languages"
          label="Select Languages"
          control={control}
          options={languageOptions}
        />
        <RadioGroup
          name="is_student"
          control={control}
          label="Are you a medical student?"
          options={[
            { value: "YES", label: "Yes" },
            { value: "NO", label: "No" },
          ]}
        /> */}
        <div className="flex flex-col items-center justify-center">
          <SubmitButton label="Submit" isLoading={isPending} />
        </div>
      </form>
    </AuthLayout>
  );
};

export default MedicalHistory;
