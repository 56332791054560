/* eslint-disable react/react-in-jsx-scope */
import ActionButton from "../Components/Common/ActionButton";
import { useNavigate } from "react-router-dom";

interface LayoutProps {
  title: string;
  children: React.ReactNode;
  label?: string;
  link?: string;
  status?: string;
}

const HeaderLayout: React.FC<LayoutProps> = ({
  title,
  children,
  label,
  link,
  status,
}) => {
  const navigate = useNavigate();
  return (
    <main className="flex-grow rounded-lg bg-white">
      <div className=" bg-headerGray p-4 rounded-t-lg flex justify-between items-center">
        <h1 className="text-xl mb-4 mt-2">{title}</h1>
        {label !== undefined && (
          <ActionButton
            label={label}
            onClick={() => navigate(link !== undefined ? link : "")}
          />
        )}
        {status !== undefined && status === "accepted" && (
          <div className="bg-doctorLittleGreen text-white px-8 py-2 rounded-full">
            Seeker
          </div>
        )}
        {status !== undefined && status === "rejected" && (
          <div className="bg-red-600 text-white px-8 py-2 rounded-full">
            Rejected
          </div>
        )}
        {status !== undefined && status === "candidate" && (
          <div className="bg-primary text-white px-8 py-2 rounded-full">
            Candidate
          </div>
        )}
      </div>
      <div className="mt-4 p-4">{children}</div>
    </main>
  );
};

export default HeaderLayout;
