/* eslint-disable */
import { AxiosResponse } from "axios";

import { useQuery, UseQueryResult } from "@tanstack/react-query";
import axiosInstance from "../../common/AxiosInstance";
interface ApiResponse {
  uid: string;
}
const fetchData = async (): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse<ApiResponse> = await axiosInstance.get(
      `portal/portal/profile/`,
    );
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};
const useGetAdminProfile = (): UseQueryResult<ApiResponse, Error> => {
  return useQuery<ApiResponse, Error>({
    queryKey: ["adminprofile"],
    queryFn: fetchData,
  });
};

export default useGetAdminProfile;
