/* eslint-disable */

import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";

import useAuthToken from "../../common/useAuthToken";
import axiosInstance from "../../common/AxiosInstance";
import { LoginUserParams } from "../../../config/types";

type Data = {
  token: string;
};
type Response = {
  data: Data;
};

// Define a type for the error
type ResponseError = {
  message: string;
};

const loginUser = async (data: LoginUserParams) => {
  const response = await axiosInstance.post("/auth/jwt/login/", data);
  return response;
};

const useLoginUser = (): UseMutationResult<
  Response,
  ResponseError, // Use the defined ResponseError type
  LoginUserParams
> => {
  const { setAuthToken } = useAuthToken();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: loginUser,
    onSuccess: (response: Response) => {
      setAuthToken(response.data.token);

      queryClient
        .invalidateQueries()
        .then(() => {
          window.location.assign("/dashboard");
        })
        .catch((error: Error) => {
          throw new Error(error.message);
        });
    },
    onError: (error: ResponseError) => {
      // Handle error here if needed
      console.error(error.message);
    },
  });
};

export default useLoginUser;
