/* eslint-disable */

import React from "react";
import SubmitButton from "../../Components/Common/SubmitButton";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import AuthLayout from "../../Layouts/AuthLayout";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../Components/Forms/Input";
import DOBInput from "../../Components/Forms/DateOfBirthInput";
import { CreateCarerContactDetails } from "../../config/types";
import { createCarerContactDetailsSchema } from "../../config/validationSchema";
import { useNavigate, useParams } from "react-router-dom";
import useUpdateCarerContactDetails from "../../Hooks/services/Carers/useUpdateCarerContactDetails";
type Props = {
  handleNextStep: () => void;
};
const ContactInformation = ({ handleNextStep }: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<CreateCarerContactDetails>({
    resolver: yupResolver(createCarerContactDetailsSchema),
  });
  const { mutateAsync, isPending } = useUpdateCarerContactDetails();
  const onSubmit: SubmitHandler<CreateCarerContactDetails> = async (
    data: any
  ) => {
    const post = { ...data, carer: params.id };
    await mutateAsync(post)
      .then(() => handleNextStep())
      .catch(() =>
        toast.error("There was an error adding the details", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      );
  };
  return (
    <AuthLayout
      title="Add Your Contact Details"
      label="Please provide your contact information"
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="md:w-3/4 w-full mt-4 mb-4 mx-auto justify-center"
      >
        <Input<CreateCarerContactDetails>
          name="postal_code"
          label="Postal Code"
          control={control}
          placeholder="Enter your postal code"
        />
        <Input<CreateCarerContactDetails>
          name="city"
          label="City"
          control={control}
          placeholder="Enter your city"
        />
        <Input<CreateCarerContactDetails>
          name="next_of_kin_name"
          label="Next of kin name"
          control={control}
          placeholder="Enter name"
          type="text"
        />
        <Input<CreateCarerContactDetails>
          name="next_of_kin_email"
          label="Next of kin email"
          control={control}
          placeholder="Enter email"
          type="email"
        />
        <Input<CreateCarerContactDetails>
          name="next_of_kin_relationship"
          label="Next of kin relationship"
          control={control}
          placeholder="Enter relationship"
          type="text"
        />
        <Input<CreateCarerContactDetails>
          name="next_of_kin_phone_number"
          label="Next of kin phone number"
          control={control}
          placeholder="Enter phone number"
          type="phone"
        />

        <div className="flex flex-col items-center justify-center">
          <SubmitButton label="Submit" isLoading={isPending} />
        </div>
      </form>
    </AuthLayout>
  );
};

export default ContactInformation;
