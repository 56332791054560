/* eslint-disable react/react-in-jsx-scope */
import { motion } from "framer-motion";

interface LayoutProps {
  children: React.ReactNode;
}
const DialogLayout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <motion.div
      className="fixed top-0 right-0 h-full w-6/12 border-t-4 border-primary rounded-tl-lg shadow-lg bg-white  z-50"
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      {children}
    </motion.div>
  );
};

export default DialogLayout;
