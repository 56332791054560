/* eslint-disable react/react-in-jsx-scope */
import { NavLink, useLocation } from "react-router-dom";

interface LayoutProps {
  title: string;
  children: React.ReactNode;
}

const BreadCrumbLayout: React.FC<LayoutProps> = ({ title, children }) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  // Mapping of route segments to breadcrumb labels
  const breadcrumbMapping: { [key: string]: string } = {
    "invite-patient": "Invite Patient",
    patients: "Patients",
    "invite-carer": "Invite Carer",
    carers: "Carers",
    users: "Users",
    "create-user": "Create User",
    "single-carer": "Carer",
    "single-patient": "Patient",
    "single-user": "User",
    "create-appointment": "Appointment Creation",
  };

  return (
    <main className="flex-grow bg-lightGray p-8">
      <div className="flex space-x-4">
        <h1 className="text-2xl mb-4 mt-2">{title}</h1>
        <span className="text-black border border-r-black" />
        <nav className="text-gray-700 mb-4">
          <ol className="list-reset flex mt-2">
            <li>
              <NavLink to="/" className="text-black hover:text-primary text-lg">
                Home
              </NavLink>
            </li>
            {pathnames.map((pathname, index) => {
              const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;

              // Skip rendering the ID segment or any other segment you want to hide
              if (
                !breadcrumbMapping[pathname] &&
                index === pathnames.length - 1
              ) {
                return null;
              }

              const label =
                breadcrumbMapping[pathname] ||
                pathname.charAt(0).toUpperCase() + pathname.slice(1);

              return (
                <li key={routeTo} className="flex items-center">
                  <span className="mx-2">-</span>
                  <NavLink
                    to={routeTo}
                    className="text-black hover:text-primary text-lg"
                  >
                    {label}
                  </NavLink>
                </li>
              );
            })}
          </ol>
        </nav>
      </div>

      <div className="mt-4">{children}</div>
    </main>
  );
};

export default BreadCrumbLayout;
