// CollapsibleContainer.tsx
import React, { useState } from "react";

interface CollapsibleContainerProps {
  title: string;
  children: React.ReactNode;
}

const CollapsibleContainer: React.FC<CollapsibleContainerProps> = ({
  title,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="border border-gray-300 rounded-lg shadow-md">
      <button
        onClick={toggleCollapse}
        className="w-full px-4 font-bold text-black py-4 text-left bg-lightGray border-b border-gray-300 rounded-t-lg focus:outline-none"
      >
        {title}
        <span
          className={`float-right transform transition-transform ${isOpen ? "rotate-180" : ""}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m19.5 8.25-7.5 7.5-7.5-7.5"
            />
          </svg>
        </span>
      </button>
      {isOpen && <div className="p-4 bg-white">{children}</div>}
    </div>
  );
};

export default CollapsibleContainer;
