import React from "react";
import { useController, Control, FieldValues, Path } from "react-hook-form";

// Define props for the Textarea component
interface TextareaProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  placeholder?: string;
  disabled?: boolean;
  rows?: number; // Added to control the number of rows for the textarea
}

// Textarea component definition
const Textarea = <T extends FieldValues>({
  name,
  label,
  control,
  placeholder = "",
  disabled = false,
  rows = 4, // Default rows set to 4
}: TextareaProps<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: undefined, // Initialize with an empty string for textarea
  });

  return (
    <div className="mb-4">
      <label htmlFor={name} className="block text-black text-sm font-bold mb-2">
        {label}
      </label>
      <textarea
        id={name}
        placeholder={placeholder}
        disabled={disabled}
        rows={rows}
        {...field}
        className={`shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline ${
          error ? "border-red-500" : ""
        }`}
      />
      {error && <p className="text-red-500 text-xs mt-2">{error.message}</p>}
    </div>
  );
};

export default Textarea;
