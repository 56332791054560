/* eslint-disable */

import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { CreateAdminParams } from "../../../config/types";
import axiosInstance from "../../common/AxiosInstance";

const createAdmin = async (data: CreateAdminParams) => {
  const location =
    data.location.postal_code === null ||
    data.location.street === null ||
    data.location.city === null
      ? (({ postal_code, street, city, ...rest }) => rest)(data.location)
      : { ...data.location };
  const newData = {
    ...data,
    location: data.location,
  };
  const response = await axiosInstance.post(`/portal/`, newData);

  return response.data; // Assuming you need just the data part of the response
};

const useCreateAdmin = (): UseMutationResult<
  // Typing the result as `any` can be replaced with a more specific type
  any,
  Error, // Use `Error` or a more specific error type here
  CreateAdminParams
> => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createAdmin,
    onSuccess: () => {
      queryClient
        .invalidateQueries()
        .then(() => {
          navigate("/thank-you");
        })
        .catch((error: Error) => {
          // Use `Error` type or a more specific error type
          console.error("Error invalidating queries:", error);
          throw new Error(error.message);
        });
    },
  });
};

export default useCreateAdmin;
