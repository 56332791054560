/* eslint-disable */

import { AxiosResponse } from "axios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import axiosInstance from "../../common/AxiosInstance";
import { AppointmentAllocationResponse } from "../../../config/types";

const fetchData = async (): Promise<AppointmentAllocationResponse> => {
  try {
    const response: AxiosResponse<AppointmentAllocationResponse> =
      await axiosInstance.get(`booking/allocate/booking/`);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};

const useGetAppointmentsAllocations = (): UseQueryResult<
  AppointmentAllocationResponse,
  Error
> => {
  return useQuery<AppointmentAllocationResponse, Error>({
    queryKey: ["appointmentsAllocation"],
    queryFn: fetchData,
  });
};

export default useGetAppointmentsAllocations;
