/* eslint-disable */

import React from "react";
import { Facebook, Linkedin, Instagram } from "lucide-react";
import Logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <footer className="bg-black text-white py-12 px-6">
        <div className="container mx-auto">
          <div className="flex items-center h-16">
            <img src={Logo} alt="Doctor Little logo" className=" w-fit h-48" />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Logo and Tagline */}
            <div>
              <h3 className="text-3xl font-bold mb-6">Better Care For All.</h3>
              <h4 className="font-normal text-white mb-4">Contact Us</h4>
              <p className="mb-2 font-normal text-white">
                Address: NAIROBI OFFICE
              </p>
              <p className="mb-2 font-normal text-white">
                Delta Corner Annex, Ring Rd Westlands Ln, Nairobi, Kenya
              </p>
              <p className="mb-4 font-normal text-white">
                Email: info@doctorlittle.org
              </p>
              <p className="mb-2 font-normal text-white">
                Address: LONDON OFFICE
              </p>
              <p className="mb-2 font-normal text-white">
                Deignes Drive, London, United Kingdom, SE13 6PD
              </p>
              <p className="mb-2 font-normal text-white">NEW YORK OFFICE</p>
              <p className="mb-2 font-normal text-white">
                367 St Marks Ave, Brooklyn, NY 11238
              </p>
              <p className="mb-2 font-normal text-white">
                Email: info@doctorlittle.org
              </p>
              <button
                onClick={() => navigate("/jobs")}
                className="bg-primary text-white font-medium py-2 px-8 rounded-full mt-6 hover:bg-primary transition duration-300"
              >
                Find a healthcare job
              </button>
            </div>

            {/* Menu */}
            <div className="md:text-center">
              <h4 className="font-bold text-lg mb-4">Menu</h4>
              <ul>
                <li className="mb-2">
                  <a href="#" className="hover:text-green-400">
                    Home
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="hover:text-green-400">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-green-400">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>

            {/* Contact Us */}
            <div className="md:text-right">
              <h4 className="font-bold text-lg mb-4">Contact us</h4>
              <div className="flex lg:justify-end flex-col space-y-4  lg:items-end">
                <a href="#" className="hover:text-green-400 flex space-x-4">
                  Facebook
                  <Facebook className="ml-2" />
                </a>
                <a href="#" className="hover:text-green-400 flex space-x-4">
                  Linkedin
                  <Linkedin className="ml-2" />
                </a>
                <a href="#" className="hover:text-green-400 flex space-x-4">
                  Instagram
                  <Instagram className="ml-2" />
                </a>
              </div>
            </div>
          </div>

          <div className="mt-12 pt-6  text-footerGray flex flex-wrap justify-center space-x-4 text-sm">
            <a href="#" className="hover:text-green-400">
              Privacy Policy
            </a>
            <a href="#" className="hover:text-green-400">
              Terms of Use
            </a>
            <a href="#" className="hover:text-green-400">
              Sales and Refunds
            </a>
            <a href="#" className="hover:text-green-400">
              Legal
            </a>
            <a href="#" className="hover:text-green-400">
              Site Map
            </a>
          </div>
        </div>
      </footer>
      <div className="text-center bg-white py-8 mt-6 text-sm">
        © 2024 DoctorLittle. All rights reserved.
      </div>
    </>
  );
};

export default Footer;
