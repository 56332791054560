/* eslint-disable */

import * as Yup from "yup";
export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),

  password: Yup.string().required("Password is required"),
});
export const createUserSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters long"),

  last_name: Yup.string()
    .required("Last name is required")
    .min(2, "Last name must be at least 2 characters long"),

  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phone_number: Yup.string()
    .required("Phone number is required")
    .matches(/^\+?\d{10,14}$/, "Phone number is not valid"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[@$!%*?&]/,
      "Password must contain at least one special character",
    ),
  date_of_birth: Yup.string()
    .nullable()
    .required("Date of Birth is required")
    .matches(/^\d{4}-\d{2}-\d{2}$/, "Date must be in the format YYYY-MM-DD"),
});
export const signupSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters long"),

  last_name: Yup.string()
    .required("Last name is required")
    .min(2, "Last name must be at least 2 characters long"),

  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),

  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[@$!%*?&]/,
      "Password must contain at least one special character",
    ),

  website: Yup.string()
    .url("Invalid URL format")
    .required("Website is required"),
  location: Yup.object().shape({
    formattedAddress: Yup.string().required("Location is required"),
  }),
  phone_number: Yup.string()
    .required("Phone number is required")
    .matches(/^\+?\d{10,14}$/, "Phone number is not valid"),
});

export const createPatientSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters long"),

  last_name: Yup.string()
    .required("Last name is required")
    .min(2, "Last name must be at least 2 characters long"),

  gender: Yup.string().required("Gender is required"),

  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),

  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[@$!%*?&]/,
      "Password must contain at least one special character",
    ),

  location: Yup.object().shape({
    formattedAddress: Yup.string().required("Location is required"),
  }),

  terms_and_conditions: Yup.boolean()
    .required("You must agree to the terms and conditions.")
    .oneOf([true], "You must agree to the terms and conditions."),

  consent_hipaa: Yup.boolean()
    .required("You must consent to HIPAA and privacy regulations.")
    .oneOf([true], "You must consent to HIPAA and privacy regulations."),

  phone_number: Yup.string()
    .required("Phone number is required")
    .matches(/^\+?\d{10,14}$/, "Phone number is not valid"),

  date_of_birth: Yup.string()
    .nullable()
    .required("Date of Birth is required")
    .matches(/^\d{4}-\d{2}-\d{2}$/, "Date must be in the format YYYY-MM-DD"),
});

export const createCarerSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters long"),

  last_name: Yup.string()
    .required("Last name is required")
    .min(2, "Last name must be at least 2 characters long"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),

  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[@$!%*?&]/,
      "Password must contain at least one special character",
    ),
  location: Yup.object().shape({
    formattedAddress: Yup.string().required("Location is required"),
  }),
  phone_number: Yup.string()
    .required("Phone number is required")
    .matches(/^\+?\d{10,14}$/, "Phone number is not valid"),
  date_of_birth: Yup.string()
    .nullable()
    .required("Date of Birth is required")
    .matches(/^\d{4}-\d{2}-\d{2}$/, "Date must be in the format YYYY-MM-DD"),
});
export const updatePatientSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters long"),

  last_name: Yup.string()
    .required("Last name is required")
    .min(2, "Last name must be at least 2 characters long"),

  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),

  phone_number: Yup.string()
    .required("Phone number is required")
    .matches(/^\+?\d{10,14}$/, "Phone number is not valid"),
  gender: Yup.string().required("Gender is required"),
  date_of_birth: Yup.string()
    .nullable()
    .required("Date of Birth is required")
    .matches(/^\d{4}-\d{2}-\d{2}$/, "Date must be in the format YYYY-MM-DD"),
});
export const createAppointmentSchema = Yup.object().shape({
  patient: Yup.string().required("Patient is required"),
  start_date: Yup.string()
    .nullable()
    .required("Start date is required")
    .matches(/^\d{4}-\d{2}-\d{2}$/, "Date must be in the format YYYY-MM-DD"),
  end_date: Yup.string()
    .nullable()
    .required("End date is required")
    .matches(/^\d{4}-\d{2}-\d{2}$/, "Date must be in the format YYYY-MM-DD"),
  rate: Yup.number()
    .required("Rate is required")
    .positive("Rate must be a positive number")
    .typeError("Rate must be a number"),
  location: Yup.object().shape({
    formattedAddress: Yup.string().required("Location is required"),
  }),
  start_time: Yup.string()
    .required("Time is required")
    .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, "Time must be in the format HH:MM"),
  end_time: Yup.string()
    .required("Time is required")
    .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, "Time must be in the format HH:MM"),
});
export const createCarerContactDetailsSchema = Yup.object({
  postal_code: Yup.string().required("Postal code is required"),
  city: Yup.string().required("City is required"),
  next_of_kin_email: Yup.string()
    .email("Invalid email format")
    .required("Next of kin email is required"),
  next_of_kin_phone_number: Yup.string().required(
    "Next of kin phone number is required",
  ),
  next_of_kin_relationship: Yup.string().required(
    "Next of kin relationship is required",
  ),
  next_of_kin_name: Yup.string().required("Next of kin name is required"),
});
export const createCarerWorkerDetailsSchema = Yup.object({
  nationality: Yup.string().required("nationality is required"),
  national_insurance: Yup.string().required(
    "national insurance number is required",
  ),
  is_student: Yup.string().required("Please select one"),
  marital_status: Yup.string().required("Marital status is required"),
  languages: Yup.array().required("At least one language must be selected"),
});

export const createPatientsSymptomsSchema = Yup.object({
  description: Yup.string().required("Description is required"),
  affected_area: Yup.string().required("This field is required"),
  symptom_duration: Yup.string().required("This field is required"),
  duration_type: Yup.string().required("This field is required"),
  severity_level: Yup.string().required("This field is required"),
  painscale: Yup.string().required("This field is required"),
});
export const createPatientsMedicalHistorySchema = Yup.object({
  existing_conditions: Yup.array()
    .of(
      Yup.string()
        .required("Condition is required")
        .min(3, "Condition must be at least 3 characters long"),
    )
    .min(1, "At least one existing condition is required")
    .max(10, "You can add up to 10 conditions")
    .required("This field is required"),

  current_medications: Yup.array()
    .of(
      Yup.string()
        .required("Medication is required")
        .min(3, "Medication must be at least 3 characters long"),
    )
    .min(1, "At least one medication is required")
    .max(10, "You can add up to 10 medications")
    .required("This field is required"),

  allergies: Yup.array()
    .of(
      Yup.string()
        .required("Allergy is required")
        .min(3, "Allergy must be at least 3 characters long"),
    )
    .min(1, "At least one allergy is required")
    .max(10, "You can add up to 10 allergies")
    .required("This field is required"),

  family_history: Yup.array()
    .of(
      Yup.string()
        .required("Family history item is required")
        .min(3, "Family history item must be at least 3 characters long"),
    )
    .min(1, "At least one family history item is required")
    .max(10, "You can add up to 10 family history items")
    .required("This field is required"),
});

export const fileUploadSchema = Yup.object().shape({
  document: Yup.object().shape({
    file: Yup.mixed<File>()
      .required("File is required")
      .test(
        "fileType",
        "Unsupported file format. Please upload a PDF or image file.",
        (value) => {
          // TypeScript assertion to tell TypeScript that value is a File or null
          if (!value || !(value instanceof File)) return false;

          const supportedFormats = [
            "application/pdf",
            "image/jpeg",
            "image/png",
          ];
          return supportedFormats.includes(value.type);
        },
      ),
    file_type: Yup.string().required("File type is required"),
  }),
});
