import React from "react";
import { useController, Control, FieldValues, Path } from "react-hook-form";

interface RadioGroupProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  label: string;
  options: { value: string; label: string }[];
  disabled?: boolean;
}

const RadioGroup = <T extends FieldValues>({
  name,
  control,
  label,
  options,
  disabled = false,
}: RadioGroupProps<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: undefined,
  });

  return (
    <div className="mb-4 radio-group">
      <label className="block text-black text-sm font-bold mb-2">{label}</label>
      <div className="flex flex-col space-y-2">
        {options.map((option) => (
          <label key={option.value} className="inline-flex items-center">
            <input
              type="radio"
              value={option.value}
              checked={field.value === option.value}
              onChange={(e) => field.onChange(e.target.value)}
              onBlur={field.onBlur}
              disabled={disabled}
              className={`form-radio text-green-500 accent-doctorLittleGreen  focus:ring-green-500 ${
                error ? "border-red-500" : ""
              }`}
            />
            <span className="ml-2 text-black">{option.label}</span>
          </label>
        ))}
      </div>
      {error && <p className="text-red-500 text-xs mt-2">{error.message}</p>}
    </div>
  );
};

export default RadioGroup;
