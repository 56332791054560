/* eslint-disable */
import { AxiosResponse } from "axios";

import { useQuery, UseQueryResult } from "@tanstack/react-query";
import axiosInstance from "../../common/AxiosInstance";
import { ApiResponse } from "../../../config/types";
interface UserOptions {
  uid?: string;
  admin__uid?: string;
}
const fetchData = async (options: UserOptions): Promise<ApiResponse> => {
  const { uid, admin__uid } = options;

  const queryParams = new URLSearchParams({
    limit: "10",
  });
  if (uid !== undefined && uid !== "") {
    queryParams.append("uid", uid);
  }
  if (admin__uid !== undefined && admin__uid !== "") {
    queryParams.append("admin__uid", admin__uid);
  }
  try {
    const response: AxiosResponse<ApiResponse> = await axiosInstance.get(
      `portal/portal-users/users/?${queryParams}`,
    );
    return response.data;
    // eslint-disable-next-line
  } catch (error: any) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};
const useGetAdminUsers = (
  options: UserOptions,
): UseQueryResult<ApiResponse, Error> => {
  const { uid, admin__uid } = options;
  return useQuery<ApiResponse, Error>({
    queryKey: ["users", uid, admin__uid],
    queryFn: () => fetchData(options),
  });
};

export default useGetAdminUsers;
