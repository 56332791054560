/* eslint-disable */
import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import axiosInstance from "../../common/AxiosInstance";

type AppointmentAllocationParams = {
  status: string;
  booking: string;
  carer: string;
};
const createAppointmentAllocation = async (
  data: AppointmentAllocationParams,
) => {
  const response = await axiosInstance.post(`/booking/allocate/booking/`, data);

  return response;
};

const useCreateAppointmentAllocation = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Response,
  AppointmentAllocationParams
> => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createAppointmentAllocation,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: ["appointmentsAllocation"] })
        .catch((error: Error) => {
          throw new Error(error.message);
        });
    },
  });
};

export default useCreateAppointmentAllocation;
