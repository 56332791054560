/* eslint-disable */

import BreadCrumbLayout from "../../Layouts/BreadCrumbLayout";
import HeaderLayout from "../../Layouts/HeaderLayout";
import Table from "../../Components/Common/Table";
import useGetCarers from "../../Hooks/services/Carers/useGetCarers";
import Spinner from "../../Components/Common/Spinner";
import { useNavigate } from "react-router-dom";
import useGetAdminProfile from "../../Hooks/services/Admin/useGetAdminProfile";

const Carer = () => {
  const navigate = useNavigate();
  const { data: profile, isLoading: profileLoading } = useGetAdminProfile();
  const { data: carers, isLoading } = useGetCarers({
    admin__uid: profile?.uid,
  });

  // Define the columns for the table
  const columns = [
    { key: "user.first_name", label: "First Name" },
    { key: "user.last_name", label: "Last Name" },
    { key: "phone_number", label: "Phone Number" },
    { key: "date_of_birth", label: "Date of Birth" },
    { key: "location.city", label: "City" },
    { key: "location.region", label: "Region" },
    { key: "location.country.name", label: "Country" },
  ];

  // Handler when viewing a single carer
  const onView = (id: string | undefined) => {
    navigate(`/carers/single-carer/${id}`);
  };

  if (isLoading || profileLoading) {
    return <Spinner />;
  }

  return (
    <BreadCrumbLayout title="Carers">
      <HeaderLayout
        title="Carers"
        label="Invite Carer"
        link="/carers/invite-carer"
      >
        <div className="container">
          <Table
            data={carers?.results || []} // Provide the carers data, defaulting to an empty array if undefined
            columns={columns}
            rowsPerPage={10} // Set the desired number of rows per page
            onView={onView}
          />
        </div>
      </HeaderLayout>
    </BreadCrumbLayout>
  );
};

export default Carer;
