/* eslint-disable react/react-in-jsx-scope */
import Spinner from "../../Components/Common/Spinner";
import useGetAdminProfile from "../../Hooks/services/Admin/useGetAdminProfile";
import BreadCrumbLayout from "../../Layouts/BreadCrumbLayout";
import HeaderLayout from "../../Layouts/HeaderLayout";

const Dashboard = () => {
  const iframeUrl =
    "https://lookerstudio.google.com/embed/reporting/f1b4f2a4-dac9-442a-bec2-d8782ef0312d/page/P2J9D";
  return (
    <BreadCrumbLayout title="Dashboard">
      <HeaderLayout title="Dashboard">
        <div className="container">
          <iframe
            height="1800"
            className="w-full"
            src={iframeUrl}
            frameBorder="0"
            style={{ border: "0" }}
            allowFullScreen
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          />
        </div>
      </HeaderLayout>
    </BreadCrumbLayout>
  );
};

export default Dashboard;
