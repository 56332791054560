/* eslint-disable */

import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import useCreateCarer from "../../Hooks/services/Carers/useCreateCarer";
import Select from "../../Components/Forms/Select";
import SubmitButton from "../../Components/Common/SubmitButton";
import AuthLayout from "../../Layouts/AuthLayout";
import { createPatientsSymptomsSchema } from "../../config/validationSchema";
import { Option } from "react-multi-select-component"; // Importing the MultiSelect component
import MultiSelect from "../../Components/Forms/MultiSelect";
import {
  externalBodyParts,
  languageOptions,
  nationalityOptions,
  painRates,
} from "../../config/utils";
import RadioGroup from "../../Components/Forms/RadioInput";
import Input from "../../Components/Forms/Input";
import useUpdateCarer from "../../Hooks/services/Carers/useUpdateCarer";
import { toast } from "react-toastify";
import Textarea from "../../Components/Forms/TextArea";
import { CreatePatientSymptoms } from "../../config/types";
import useAddPatientSymptoms from "../../Hooks/services/Patients/useAddPatientSymptoms";

// Define your SymptomsChecker component
const SymptomsChecker = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<CreatePatientSymptoms>({
    resolver: yupResolver(createPatientsSymptomsSchema),
  });

  const { mutateAsync, isPending } = useAddPatientSymptoms();

  const onSubmit: SubmitHandler<CreatePatientSymptoms> = async (
    data: CreatePatientSymptoms, // Use the correct type
    e: React.BaseSyntheticEvent | undefined, // Ensure correct event type
  ) => {
    const post = { ...data, patient_uid: params.id };
    await mutateAsync(post)
      .then(() => navigate(`/patient/medical-history/${params.id}`))
      .catch(() =>
        toast.error("There was an error adding the details", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      );
  };
  // Define the options for the multi-select component

  return (
    <AuthLayout other label="Symptoms Details">
      <form
        className="w-3/4 mt-4 mb-4 mx-auto justify-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Select
          name="affected_area"
          label="What part of the body is affected?"
          control={control}
          options={externalBodyParts}
          placeholder="select a body part"
        />
        <div className="flex justify-between ">
          <Input<CreatePatientSymptoms>
            name="symptom_duration"
            label="Symptom Duration"
            control={control}
            placeholder="Enter the duration"
          />

          <Select
            name="duration_type"
            label="Days/weeks/months/years"
            control={control}
            options={[
              { value: "DAYS", label: "Days" },
              { value: "WEEKS", label: "Weeks" },
              { value: "MONTHS", label: "Months" },
              { value: "YEARS", label: "Years" },
            ]}
            placeholder="select duration type"
          />
        </div>
        <Select
          name="severity_level"
          label="Severity of the symptoms"
          control={control}
          options={[
            { value: "MILD", label: "Mild" },
            { value: "MODERATE", label: "Moderate" },
            { value: "SEVERE", label: "Severe" },
          ]}
          placeholder="select duration type"
        />
        <Textarea
          name="description"
          label="Symptoms Description"
          control={control}
          placeholder="Enter a description"
        />

        <Select
          name="painscale"
          label="What's the pain level on a scale of 1-10"
          control={control}
          options={painRates}
          placeholder="select a nationality"
        />
        {/* <MultiSelect
          name="languages"
          label="Select Languages"
          control={control}
          options={languageOptions}
        />
        <RadioGroup
          name="is_student"
          control={control}
          label="Are you a medical student?"
          options={[
            { value: "YES", label: "Yes" },
            { value: "NO", label: "No" },
          ]}
        /> */}
        <div className="flex flex-col items-center justify-center">
          <SubmitButton label="Submit" isLoading={isPending} />
        </div>
      </form>
    </AuthLayout>
  );
};

export default SymptomsChecker;
