import React from "react";
import AboutImage1 from "../../assets/about-image-1.png";
import AboutImage2 from "../../assets/about-image-2.png";
import { useNavigate } from "react-router-dom";
import "./styles.css";
interface ValueProps {
  title: string;
  description: string;
}

const ValueItem: React.FC<ValueProps> = ({ title, description }) => (
  <li className="flex items-start">
    <svg
      className="w-5 h-5 text-green-500 mr-2 mt-1 flex-shrink-0"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
        clipRule="evenodd"
      />
    </svg>
    <span>
      <strong>{title}:</strong> {description}
    </span>
  </li>
);
const AboutUsSection: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="container mx-auto px-4 py-16">
      <h2 className="text-3xl font-semibold text-center mb-12">
        WE USE THE POWER OF TECHNOLOGY
        <br /> TO ENABLE BETTER CARE
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12 items-center">
        <div className="md:order-1">
          <div className="md:order-2 lg:hidden medium:hidden">
            <img
              src={AboutImage1}
              alt="Healthcare professional with patient"
              className="w-fit h-fut shadow-image rounded-lg"
            />
          </div>
          <h3 className="text-3xl text-black font-semibold mb-2">
            Company Overview
          </h3>
          <p className="text-lg text-black font-medium mb-7">
            Integer malesuada urna ut velit fermentum
          </p>
          <p className="text-gray-700 mb-4">
            At Doctor Little, we envision a world where everyone has access to
            exceptional care—united in the pursuit of better health. Driven by
            our passion for innovative solutions and our commitment to precision
            medicine, we aim to enhance human well-being. Whether we're
            developing cutting-edge supplements or advancing AI-driven health
            technologies, our mission is clear: to deliver better care and
            innovation to every individual worldwide. Because, as we believe,
            everyone deserves access to optimal health. Every day, we strive for
            a future where healthcare is equitable, inclusive, and
            transformative. We believe that diversity drives innovation and
            accelerates progress. We are dedicated to a world where every person
            can thrive and receive the care they deserve.
          </p>
          <button
            onClick={() => navigate("/carer/create-carer")}
            className="bg-primary mt-12 hidden md:flex text-white font-semibold py-2 px-8 rounded-full hover:bg-doctorLittleGreen transition duration-300"
          >
            Register
          </button>
        </div>
        <div className="md:order-2 hidden md:flex lg:ml-40">
          <img
            src={AboutImage1}
            alt="Healthcare professional with patient"
            className="w-fit h-fut shadow-image rounded-lg"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <img
          src={AboutImage2}
          alt="Healthcare professional with patient"
          className="w-fit image-shadow h-fit rounded-lg"
        />
        <div className="order-1 md:order-2">
          <div className="bg-white p-6 rounded-lg shadow-md mb-4">
            <h3 className="text-2xl font-semibold mb-4">Our Mission</h3>
            <p className="text-gray-700">
              To leverage technology to provide personalized care across all
              levels, from foundational support at the base of the pyramid to
              advanced solutions at the top.
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold mb-4">Our Values</h3>
            <ul className="space-y-3">
              <ValueItem
                title="Quality of Care"
                description="Delivering exceptional, evidence-based healthcare
              solutions to enhance well-being for all."
              />
              <ValueItem
                title="Community"
                description=" Building supportive, inclusive environments where diverse 
              voices drive innovation and progress."
              />
              <ValueItem
                title="Create Opportunity"
                description=" Advancing access to healthcare and empowering 
              individuals through innovative solutions and equitable practices"
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;
