/* eslint-disable */

import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";

import axiosInstance from "../../common/AxiosInstance";
import { CreateCarerWorkExperience } from "../../../config/types";

const addCarerWorkExperience = async (data: CreateCarerWorkExperience) => {
  const { experience: worker_experience } = data;

  // Loop through each work experience object and format dates
  for (let i = 0; i < worker_experience.length; i++) {
    const experience = worker_experience[i];
    const formattedExperience = {
      ...experience,
      job_title:
        experience.job_title === "Other"
          ? experience.other_name
          : experience.job_title,
      company: experience.company,
      start_date: experience.start_date,
      end_date: experience.end_date,
      carer: data.carer,
    };

    try {
      await axiosInstance.post(`carer/carer/experience/`, formattedExperience);
    } catch (error) {
      // Handle error if needed
      console.error("Error occurred while sending work experience:", error);
    }
  }
};

const useAddCarerWorkExperience = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Response,
  CreateCarerWorkExperience
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addCarerWorkExperience,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: ["carerexperience"] })
        .catch((error: Error) => {
          throw new Error(error.message);
        });
    },
  });
};

export default useAddCarerWorkExperience;
