/* eslint-disable */
import React, { useState } from "react";
import Logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import Menu from "../../assets/menu.png";
interface NavbarProps {
  handleScroll: (section: "home" | "about" | "contact") => void;
}

const Navbar: React.FC<NavbarProps> = ({ handleScroll }) => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="bg-doctorLittleGreen p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center h-16">
          <img src={Logo} alt="Doctor Little logo" className="w-fit h-48" />
        </div>

        {/* Desktop menu */}
        <div className="hidden md:flex items-center text-sm space-x-4">
          <button
            className="text-white px-4 py-2 rounded"
            onClick={() => {
              navigate("/");
              handleScroll("home");
            }}
          >
            Home
          </button>
          <button
            className="text-white px-4 py-2 rounded"
            onClick={() => {
              navigate("/");
              handleScroll("about");
            }}
          >
            About Us
          </button>
          <button
            className="text-white px-4 py-2 rounded"
            onClick={() => navigate("/jobs")}
          >
            Jobs
          </button>
          <button
            className="text-white px-4 py-2 rounded"
            onClick={() => {
              navigate("/");
              handleScroll("contact");
            }}
          >
            Contact Us
          </button>
        </div>

        {/* Mobile menu button */}
        <button
          className="md:hidden text-white"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <img src={Menu} alt="menu" className="h-fit w-fit" />
        </button>
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="md:hidden mt-2">
          <button
            className="block text-white bg-green-600 px-4 py-2"
            onClick={() => handleScroll("home")}
          >
            Home
          </button>
          <button
            className="block text-white px-4 py-2"
            onClick={() => handleScroll("about")}
          >
            About Us
          </button>
          <button
            className="block text-white px-4 py-2"
            onClick={() => handleScroll("contact")}
          >
            Contact Us
          </button>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
