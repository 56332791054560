import React from "react";

interface ProgressBarProps {
  currentStep: number; // The current active step
}

const steps = [
  "Contact Information",
  "Work details",
  "Work experience",
  "Identification details",
  "Training & licence",
];

const ProgressBar: React.FC<ProgressBarProps> = ({ currentStep }) => {
  return (
    <div className="w-full flex   items-center justify-between">
      {steps.map((step, index) => (
        <div key={index} className="flex flex-wrap items-center w-full">
          {/* Circle */}
          <div
            className={`flex items-center justify-center w-8 h-8 rounded-full 
              ${
                index === currentStep
                  ? "bg-green-500" // Active step
                  : "bg-gray-200"
              } // Inactive steps
            `}
          />

          {/* Step title */}
          <div
            className={`ml-2 hidden md:flex ${index === currentStep ? "text-green-500 font-semibold" : "text-gray-400"}`}
          >
            {step}
          </div>

          {/* Divider */}
          {index < steps.length - 1 && (
            <div
              className={`flex-1 hidden md:flex  h-px mx-4 
              ${index < currentStep ? "bg-green-500" : "bg-gray-300"}`}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;
