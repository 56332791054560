/* eslint-disable */

import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import axiosInstance from "../../common/AxiosInstance";
import { CreatePatientParams } from "../../../config/types";

const createPatient = async (data: CreatePatientParams) => {
  const location =
    data.location.postal_code === null ||
    data.location.street === null ||
    data.location.city === null
      ? (({ postal_code, street, city, ...rest }) => rest)(data.location)
      : { ...data.location };
  const newData = {
    ...data,
    admin: "34ac5386-cd8b-49b3-b251-5d4dee843aef",
    location: location,
  };
  const response = await axiosInstance.post(`/patient/`, newData);

  return response;
};

const useCreatePatient = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Response,
  CreatePatientParams
> => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPatient,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: ["patients"] })
        .then(() => {
          navigate("/thank-you");
        })
        .catch((error: Error) => {
          throw new Error(error.message);
        });
    },
  });
};

export default useCreatePatient;
