/* eslint-disable */
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../Components/Forms/Input";
import { loginSchema } from "../../config/validationSchema";
import AuthLayout from "../../Layouts/AuthLayout";
import useLoginUser from "../../Hooks/services/Auth/useLogin";
import { toast } from "react-toastify";
import SubmitButton from "../../Components/Common/SubmitButton";
import { useNavigate } from "react-router-dom";
interface FormValues {
  password: string;
  email: string;
}
const Login = () => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(loginSchema),
  });
  const { mutateAsync, isPending } = useLoginUser();
  const onSubmit: SubmitHandler<FormValues> = async (data: any) => {
    await mutateAsync(data)
      .then(() =>
        toast.success("Logged in successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      )
      .catch(() =>
        toast.error("There was an error logging in, check your credentials", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      );
  };
  return (
    <AuthLayout label="Admin Login" title="Admin Login">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-3/4 mt-4 mb-4 mx-auto justify-center"
      >
        <Input<FormValues>
          name="email"
          label="Email"
          control={control}
          placeholder="Enter your email"
          type="email"
        />
        <Input<FormValues>
          name="password"
          label="Password"
          control={control}
          placeholder="Enter your password"
          type="password"
        />
        <div className="flex flex-col items-center justify-center">
          <SubmitButton isLoading={isPending} label="Login" />
          <div className="mt-4">
            <p className="text-black underline cursor-pointer">
              Forgot password?
            </p>
          </div>
          <p className="mt-4">OR</p>
          <div
            onClick={() => navigate("/auth/signup")}
            className="mt-4 cursor-pointer"
          >
            <p className="text-black underline cursor-pointer">
              Create a new account.
            </p>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};

export default Login;
