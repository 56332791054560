/* eslint-disable */

import { useNavigate } from "react-router-dom";
import Logo from "../assets/mainicon.png";
interface LayoutProps {
  children: React.ReactNode;
  label?: string;
  title?: string;
  subTitle?: string;
  other?: boolean;
  handlePrevStep?: () => void;
}
const AuthLayout: React.FC<LayoutProps> = ({
  children,
  label,
  other,
  title,
  subTitle,
  handlePrevStep,
}) => {
  const navigate = useNavigate();
  return (
    <div className="bg-white ">
      {other && (
        <button
          onClick={() => handlePrevStep?.()}
          className=" rounded-lg text-black px-6 py-2 md:ml-10 ml-2 mt-6"
        >
          &#8592; Go back
        </button>
      )}
      <div className="flex  flex-col items-center  justify-center p-4">
        <img src={Logo} alt="Logo" className="h-32 w-auto" />
        <h1 className="text-2xl mb-2 mt-2 font-semibold">{title}</h1>
        <p className="mb-2 text-xs font-normal">
          {label !== undefined
            ? label
            : `Thank you for your interest in joining our team at DoctorLittle.
          
          Please fill out the registration form below to get started.`}
        </p>
        <h1 className="mb-4 font-semibold text-2xl">{subTitle}</h1>
        <div className="p-4 md:w-4/12 w-full shadow-lg shadow-white bg-white rounded-2xl">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
