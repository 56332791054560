/* eslint-disable react/react-in-jsx-scope */
import { NavLink } from "react-router-dom";
import Logo from "../../assets/logo.png";
import useAuthToken from "../../Hooks/common/useAuthToken";

const Navbar = () => {
  const { setAuthToken } = useAuthToken();
  const handleLogout = () => {
    setAuthToken(null);
    window.location.assign("/auth/login");
  };
  return (
    <nav className=" bg-darkGray shadow-md py-4">
      <div className="container  flex justify-between items-center">
        <div className="flex h-20 ml-12 items-center space-x-4">
          <img src={Logo} alt="logo" className="h-36 w-full" />
        </div>

        <div className="flex space-x-8">
          <NavLink
            to="/dashboard"
            className={({ isActive }) =>
              isActive
                ? "border-b-4 border-yellow-500 py-1 text-xl"
                : "text-black py-1 text-xl"
            }
          >
            Dashboard
          </NavLink>
          <NavLink
            to="/carers"
            className={({ isActive }) =>
              isActive
                ? "border-b-4 border-yellow-500 py-1 text-xl"
                : "text-black py-1 text-xl"
            }
          >
            Carers
          </NavLink>
          <NavLink
            to="/patients"
            className={({ isActive }) =>
              isActive
                ? "border-b-4 border-yellow-500 py-1 text-xl"
                : "text-black py-1 text-xl"
            }
          >
            Patients
          </NavLink>
          <NavLink
            to="/rota"
            className={({ isActive }) =>
              isActive
                ? "border-b-4 border-yellow-500 py-1 text-xl"
                : "text-black py-1 text-xl"
            }
          >
            Rota
          </NavLink>
          <NavLink
            to="/users"
            className={({ isActive }) =>
              isActive
                ? "border-b-4 border-yellow-500 py-1 text-xl"
                : "text-black py-1 text-xl"
            }
          >
            Users
          </NavLink>
        </div>

        {/* User Information */}
        <div className="text-black flex space-x-4 items-center">
          <span>Welcome, Daniel</span>
          <button
            onClick={handleLogout}
            className="px-6 py-2 bg-black rounded-lg text-white"
          >
            Logout
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
