/* eslint-disable */
import BreadCrumbLayout from "../../Layouts/BreadCrumbLayout";
import HeaderLayout from "../../Layouts/HeaderLayout";
import Table from "../../Components/Common/Table";
import useGetAdminUsers from "../../Hooks/services/Admin/useGetAdminUsers";
import Spinner from "../../Components/Common/Spinner";
import { flattenPatientData } from "../../config/utils";
import { useNavigate } from "react-router-dom";
import useGetAdminProfile from "../../Hooks/services/Admin/useGetAdminProfile";

const Users = () => {
  const navigate = useNavigate();
  const { data: profile, isLoading: profileLoading } = useGetAdminProfile();
  const { data: users, isLoading } = useGetAdminUsers({});
  const onView = (id: string | undefined) => {
    navigate(`/users/single-user/${id}`);
  };
  const columns = [
    { key: "user.first_name", label: "First Name" },
    { key: "user.last_name", label: "Last Name" },
    { key: "phone_number", label: "Phone Number" },
    { key: "date_of_birth", label: "Date of Birth" },
    { key: "location.city", label: "City" },
    { key: "location.region", label: "Region" },
    { key: "location.country.name", label: "Country" },
  ];
  if (isLoading || profileLoading) {
    return <Spinner />;
  }
  return (
    <BreadCrumbLayout title="Users">
      <HeaderLayout title="Users" label="Create User" link="/users/create-user">
        <div className="container">
          <Table
            onView={onView}
            data={users?.results || []}
            columns={columns}
            rowsPerPage={10}
          />
        </div>
      </HeaderLayout>
    </BreadCrumbLayout>
  );
};

export default Users;
