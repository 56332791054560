/* eslint-disable react/react-in-jsx-scope */
import { Navigate, Outlet } from "react-router-dom";
import useAuthToken from "../Hooks/common/useAuthToken";

const PrivateRoute: React.FC = () => {
  const { token } = useAuthToken();

  if (token === null || !token) {
    return <Navigate to="/auth/login" replace />;
  }
  return <Outlet />;
};

export default PrivateRoute;
