/* eslint-disable react/react-in-jsx-scope */
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Home from "../Pages/Home/Home";
import Carer from "../Pages/Carers";
import Dashboard from "../Pages/Dashboard";
import InvitePatient from "../Pages/Patients/InvitePatient";
import InviteCarer from "../Pages/Carers/InviteCarer";
import Patients from "../Pages/Patients";
import Users from "../Pages/Users";
import CreateUser from "../Pages/Users/CreateUser";
import ViewSingleCarer from "../Pages/Carers/ViewSingleCarer";
import ViewSinglePatient from "../Pages/Patients/ViewSinglePatient";
import ViewSingleUser from "../Pages/Users/ViewSingleUser";
import Rota from "../Pages/Rota";
import CreateAppointment from "../Pages/Rota/CreateAppointment";

const ProtectedRoutes = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path="/carers/:page" element={<Carer />} />
        <Route path="/carers" element={<Navigate to="/carers/1" replace />} />
        <Route path="/dashboard/" element={<Dashboard />} />
        <Route path="/patients/invite-patient" element={<InvitePatient />} />
        <Route path="/carers/invite-carer" element={<InviteCarer />} />
        <Route path="/carers/single-carer/:id" element={<ViewSingleCarer />} />
        <Route
          path="/patients/single-patient/:id"
          element={<ViewSinglePatient />}
        />
        <Route path="/users/single-user/:id" element={<ViewSingleUser />} />
        <Route path="/patients/:page" element={<Patients />} />
        <Route
          path="/patients"
          element={<Navigate to="/patients/1" replace />}
        />
        <Route path="/users/:page" element={<Users />} />
        <Route path="/users" element={<Navigate to="/users/1" replace />} />
        <Route path="/users/create-user" element={<CreateUser />} />
        <Route path="/rota" element={<Rota />} />
        <Route
          path="/rota/create-appointment"
          element={<CreateAppointment />}
        />
      </Route>
    </Routes>
  );
};

export default ProtectedRoutes;
