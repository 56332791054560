/* eslint-disable */

import React from "react";
import useGetCarerDocuments from "../../Hooks/services/Carers/useGetDocuments";
import Spinner from "../../Components/Common/Spinner";

type Props = {
  id: string;
};
const ViewIdentificationDocuments = ({ id }: Props) => {
  const { data: document, isLoading } = useGetCarerDocuments({ uid: id });
  const documents = document?.results.filter(
    (item) => item.document_type === "IDENTIFICATION",
  );
  if (isLoading) return <Spinner />;
  return (
    <div className="overflow-x-auto p-4">
      <table className="min-w-full bg-white border border-lightGray">
        <thead className=" bg-darkGray">
          <tr>
            <th className="py-2 px-4 border-b text-left border-lightGray">
              Document Name
            </th>
            <th className="py-2 px-4 border-b text-left border-lightGray">
              Expiry Date
            </th>
            <th className="py-2 px-4 border-b text-left border-lightGray">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {documents?.map((document) => (
            <tr key={document.uid} className="hover:bg-darkGray">
              <td className="py-2 px-4 border-b border-lightGray">
                {document.file_name}
              </td>
              <td className="py-2 px-4 border-b border-lightGray">
                {document.expiry_date}
              </td>
              <td className="py-2 px-4 border-b border-lightGray">
                <div className="flex space-x-4">
                  <a
                    href={document.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                  </a>
                  <a href={document.url} download>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                      />
                    </svg>
                  </a>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewIdentificationDocuments;
