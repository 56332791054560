/* eslint-disable */

import { useEffect, useState } from "react";
import useGetReports from "../../Hooks/services/Appointments/useGetReports";
import Spinner from "../../Components/Common/Spinner";

type Props = {
  id: string;
  handleCloseDialog: () => void;
};
const ReportDetails = ({ id, handleCloseDialog }: Props) => {
  const { data, isLoading } = useGetReports();
  const filteredReports = data?.results.filter((item) => item.shift === id);
  if (isLoading) return <Spinner />;
  return (
    <div className="bg-lightGray p-4 border-b">
      <div className="flex justify-between mb-4  items-center ">
        <h3 className="text-lg font-medium">Report Details</h3>
        <button
          onClick={handleCloseDialog}
          className="text-xl bg-darkGray rounded-full h-8 w-8 text-center shadow-xl font-bold"
        >
          &times;
        </button>
      </div>
      <p className="p-4 w-1/2">
        {filteredReports !== undefined && filteredReports[0].report}
      </p>
    </div>
  );
};

export default ReportDetails;
