/* eslint-disable */
import AuthLayout from "../../Layouts/AuthLayout";
import Input from "../../Components/Forms/Input";
import { CreatePatientParams } from "../../config/types";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createPatientSchema } from "../../config/validationSchema";
import { toast } from "react-toastify";
import SubmitButton from "../../Components/Common/SubmitButton";
import useCreatePatient from "../../Hooks/services/Patients/useCreatePatient";
import DOBInput from "../../Components/Forms/DateOfBirthInput";
import LocationInput from "../../Components/Forms/LocationInput";
import RadioGroup from "../../Components/Forms/RadioInput";
import BooleanInput from "../../Components/Forms/BooleanInput";

const CreatePatient = () => {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CreatePatientParams>({
    resolver: yupResolver(createPatientSchema),
  });
  const { mutateAsync, isPending } = useCreatePatient();
  const onSubmit: SubmitHandler<CreatePatientParams> = async (data: any) => {
    await mutateAsync(data)
      .then(() =>
        toast.success("The user was registered successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      )
      .catch(() =>
        toast.error("There was an error creating the user", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }),
      );
  };
  return (
    <AuthLayout label="Create a patient">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-3/4 mt-4 mb-4 mx-auto justify-center"
      >
        <div className="flex justify-between">
          <Input<CreatePatientParams>
            name="first_name"
            label="First Name"
            control={control}
            placeholder="Enter your first name"
          />
          <Input<CreatePatientParams>
            name="last_name"
            label="Last Name"
            control={control}
            placeholder="Enter your last name"
          />
        </div>
        <Input<CreatePatientParams>
          name="email"
          label="Email"
          control={control}
          placeholder="Enter your email"
          type="email"
        />
        <DOBInput<CreatePatientParams>
          name="date_of_birth"
          label="Date of Birth"
          control={control}
          placeholder="YYYY-MM-DD"
        />
        <RadioGroup
          name="gender"
          control={control}
          label="What's your gender?"
          options={[
            { value: "MALE", label: "Male" },
            { value: "FEMALE", label: "Female" },
          ]}
        />

        <Input<CreatePatientParams>
          name="phone_number"
          label="Phone Number"
          control={control}
          placeholder="Enter your phone number"
          type="text"
        />
        <LocationInput<CreatePatientParams>
          name="location"
          label="Location"
          control={control}
          placeholder="Enter a location"
        />
        <Input<CreatePatientParams>
          name="password"
          label="Password"
          control={control}
          placeholder=""
          type="password"
        />
        <BooleanInput
          name="consent_hipaa"
          label="Consent to HIPAA and privacy regulations"
          register={register}
          requiredMessage="You must consent to HIPAA and privacy regulations."
        />
        {errors.consent_hipaa && (
          <p className="text-red-500 text-xs mt-2">
            {errors.consent_hipaa.message}
          </p>
        )}
        <BooleanInput
          name="terms_and_conditions"
          label="Agree to Terms and Conditions"
          register={register}
          requiredMessage="You must agree to the terms and conditions."
        />
        {errors.terms_and_conditions && (
          <p className="text-red-500 text-xs mt-2">
            {errors.terms_and_conditions.message}
          </p>
        )}
        <div className="flex flex-col items-center justify-center">
          <SubmitButton label="Submit" isLoading={isPending} />
        </div>
      </form>
    </AuthLayout>
  );
};

export default CreatePatient;
