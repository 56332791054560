/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from "react";
import { useController, Control, FieldValues, Path } from "react-hook-form";

interface InputProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
}

const MultiItemInput = <T extends FieldValues>({
  name,
  label,
  control,
  placeholder = "",
  type = "text",
  disabled = false,
}: InputProps<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: undefined, // Initialize with an empty array for multiple items
  });

  const [inputValue, setInputValue] = useState<string>(""); // Local input state
  const [items, setItems] = useState<string[]>(field.value || []); // Items list from form state

  useEffect(() => {
    setItems(field.value || []);
  }, [field.value]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && inputValue.trim()) {
      event.preventDefault(); // Prevent form submission

      // Add new item to the list and reset the input field
      const newItems = [...items, inputValue.trim()];
      setItems(newItems);
      setInputValue(""); // Clear input after adding
      field.onChange(newItems); // Update form value
    }
  };

  const handleRemoveItem = (itemToRemove: string) => {
    const newItems = items.filter((item) => item !== itemToRemove);
    setItems(newItems);
    field.onChange(newItems); // Update form value
  };

  return (
    <div className="mb-4">
      <label htmlFor={name} className="block text-black text-sm font-bold mb-2">
        {label}
      </label>
      <input
        id={name}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        className={`shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline ${
          error ? "border-red-500" : ""
        }`}
      />
      {error && <p className="text-red-500 text-xs mt-2">{error.message}</p>}

      {/* Display the list of items below the input */}
      <div className="flex flex-wrap gap-2 mt-2">
        {items.map((item, index) => (
          <div
            key={index}
            className="flex items-center bg-gray-200 px-3 py-1 rounded-full cursor-pointer hover:bg-gray-300"
            onClick={() => handleRemoveItem(item)}
          >
            {item}
            <span className="ml-2 text-red-500">✕</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiItemInput;
