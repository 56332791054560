import React from "react";
import { UseFormRegister } from "react-hook-form";

interface BooleanInputProps {
  name: string;
  label: string;
  register: UseFormRegister<any>;
  requiredMessage: string;
}

const BooleanInput: React.FC<BooleanInputProps> = ({
  name,
  label,
  register,
  requiredMessage,
}) => {
  return (
    <div>
      <label>
        <input
          type="checkbox"
          {...register(name, { required: requiredMessage })}
          className="mr-2"
        />
        {label}
      </label>
    </div>
  );
};

export default BooleanInput;
