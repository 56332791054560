/* eslint-disable */

import {
  useMutation,
  useQueryClient,
  UseMutationResult,
} from "@tanstack/react-query";

import axiosInstance from "../../common/AxiosInstance";
import { CreatePatientSymptoms } from "../../../config/types";

const addPatientSymptoms = async (data: CreatePatientSymptoms) => {
  const response = await axiosInstance.post(`patient/symptom/patient/`, data);

  return response;
};

const useAddPatientSymptoms = (): UseMutationResult<
  // eslint-disable-next-line
  any,
  Response,
  CreatePatientSymptoms
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addPatientSymptoms,
    onSuccess: () => {
      queryClient
        .invalidateQueries({ queryKey: ["patientsymptoms"] })
        .catch((error: Error) => {
          throw new Error(error.message);
        });
    },
  });
};

export default useAddPatientSymptoms;
