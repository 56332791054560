/* eslint-disable */

import { AxiosResponse } from "axios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import axiosInstance from "../../common/AxiosInstance";
import { ApiResponse } from "../../../config/types";

interface CarerOptions {
  uid?: string;
  admin_uid?: string;
}
const fetchData = async (options: CarerOptions): Promise<ApiResponse> => {
  const { uid, admin_uid } = options;

  const queryParams = new URLSearchParams({
    limit: "10",
  });
  if (uid !== undefined && uid !== "") {
    queryParams.append("uid", uid);
  }
  if (admin_uid !== undefined && admin_uid !== "") {
    queryParams.append("admin__uid", admin_uid);
  }
  try {
    const response: AxiosResponse<ApiResponse> = await axiosInstance.get(
      `/patient/?${queryParams}`,
    );
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error("An unknown error occurred");
    }
  }
};

const useGetPatients = (
  options: CarerOptions,
): UseQueryResult<ApiResponse, Error> => {
  return useQuery<ApiResponse, Error>({
    queryKey: ["patients", options.uid, options.admin_uid],
    queryFn: () => fetchData(options),
  });
};

export default useGetPatients;
